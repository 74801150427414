import gsap from "gsap";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";
import Utils from "../_utils.js";

export class MessagePage {
  constructor() {
    this.$el = "[message-page]";
  }
  init() {
    if ($(this.$el)) {
      // const utils = new Utils();
      // utils.changeOrange({
      //   section: ".top, .article, .introduction",
      //   trigger: ".article__box",
      // });
      this.openingAnimeSet();
      this.openingAnime();
      // console.log("window timeline", window.tl);
      const tl = gsap.timeline();
      tl.add(window.tl["companyLink"]);
    }
  }
  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");
    gsap.set(".top__img-box", {
      opacity: 0,
      y: 30,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");

    // console.log(window.tl);
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");
    tl.to(
      ".top__img-box",
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
      },
      "contents-=0.5"
    );
    tl.add(window.tl.topScrollOn(), "contents-=0.5");
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
}
