import * as THREE from 'three';
import common from "./Common";

import assets from "./Assets";

import vertexShader from "./glsl/background.vert";
import fragmentShader from "./glsl/background.frag";
import {selector} from "./domData"
import { getter_configs, setter_configs } from "./status";

import Blur from "./Blur"
import EventBus from '../event-bus';

import animation from "./Animation";

class Background{
  constructor(){
    this.textures = [];
    this.blurs = []
    this.count = 0;
    this.isDonePrerender = false;
    this.camera = new THREE.Camera();
    this.current = 0;
    this.nextCurrent = 0;

    this.animation = {
      isAnimation: false,
      t: 1,
      c: 1,
      e: 0.0
    }
  }

  setCurrent(num){
    if(num !== this.nextCurrent && !this.animation.isAnimation){
      this.animation.t = 0;
      this.animation.e = 4.0;
      this.animation.isAnimation = true;
      this.nextCurrent = num
    }
  }

  init(){
    this.fbo = new THREE.WebGLRenderTarget(common.dimensions.x, common.dimensions.y);
    this.$images = document.body.querySelectorAll(selector);

    for(let i = 0; i < this.$images.length; i++){
      const blur = new Blur(assets.textures["image_" + i].value);
      this.blurs[i] = blur;
    }

    this.uniforms = {
      uTexture: {
        value: this.blurs[0].texture
      },
      uTexture2: {
        value: this.blurs[0].texture
      },
      uImageRes: {
        value: this.blurs[0].dimensions
      },
      uImageRes2: {
        value: this.blurs[0].dimensions
      },
      uResolution: {
        value: common.dimensions
      },
      uOpacity: {
        value: 0
      },
      uOpening: {
        value: 0
      }
    }

    this.plane = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(2, 2),
      new THREE.ShaderMaterial({
        vertexShader,
        fragmentShader,
        uniforms: this.uniforms
      })
    );
  }

  resize(){
    this.fbo.setSize(common.dimensions.x, common.dimensions.y);
  }

  preupdate(){
    if(this.count < this.$images.length && !this.isDonePrerender){
      const texture = this.blurs[this.count].update();
      this.textures[this.count] = texture;
      this.count++;

      if(this.$images.length == this.count){
        this.isDonePrerender = true;
        setter_configs("isRendering", false)
        EventBus.emit("DONE_PRERENDER");
      }
    }
  }

  updateAnimation(){
    const ease = Math.min(1.0, common.delta * this.animation.e);
    
    this.animation.c += (this.animation.t - this.animation.c) * ease;

    if(this.animation.isAnimation && this.animation.c < 0.01){
      this.animation.c = 1.0;
      this.animation.isAnimation = false;
      this.animation.t = 1.0;
      this.animation.e = 2.0;
      this.current = this.nextCurrent;
    }
  }

  update(){
    if(this.isDonePrerender){
      this.updateAnimation();
      this.uniforms.uImageRes.value.copy(this.blurs[this.current].dimensions);
      this.uniforms.uTexture.value = this.textures[this.current];

      this.uniforms.uImageRes2.value.copy(this.blurs[this.nextCurrent].dimensions);
      this.uniforms.uTexture2.value = this.textures[this.nextCurrent];
      this.uniforms.uOpacity.value = this.animation.c;
      this.uniforms.uOpening.value = animation.opening.bg;

      common.renderer.setRenderTarget(this.fbo);
      common.renderer.render(this.plane, this.camera);
    }
  }
}

export default new Background();