import * as THREE from "three";
import common from "./Common";
import assets from "./Assets";
import { selector, box } from "./domData";
import EventBus from "../event-bus";
import vertexShader from "./glsl/panel.vert";
import fragmentShader from "./glsl/panel.frag";
import shadowFragmentShader from "./glsl/panel_shadow.frag";

import mouse from "./Mouse";
import * as MATH from "./utils/math";
import background from "./Background";

import animation from "./Animation";
import domControls from "./DomControls";

import { getter_configs, setter_configs } from "./status";

import { Plane, COLUMN_NUM, MARGIN } from "./Plane";

import FollowingBtn from "./FollowingBtn";

export default class Panels {
  constructor() {
    this.group = new THREE.Group();
    this.totalLength = new THREE.Vector2();

    this.gridSize = new THREE.Vector2();

    this.meshArray = [];
    this.numArray = [];

    this.originOffset = new THREE.Vector2();
    this.opening = new THREE.Vector2();
    this.dim = new THREE.Vector2();
    this.triggerTimer = null;
    // this.lookAtTargetVec = new THREE.Vector3();
    this.orderArray = [];

    this.currentNum = 0;

    this.followingBtn = new FollowingBtn();

    EventBus.on("SHRINK_PANEL", ({ detail }) => {
      this.currentMesh.userData.focus2.t = 1;
      this.expandPanel = this.currentMesh;
    });

    EventBus.on("EXPAND_PANEL", ({ detail }) => {
      this.expandPanel.userData.focus2.t = 0;
    });

    common.renderer.domElement.addEventListener("click", () => {
      if (this.followingBtn.isShow || common.isMobile) {
        setTimeout(() => {
          // console.log(common.renderer.domElement);
          // console.log("click!", this.currentNum);

          mouse.style("default");
          EventBus.emit("GOTO_SINGLE_INTERVIEW", {
            num: this.currentNum,
          });
        }, 0);
      }
      mouse.isMousemoving = false;
    });
    // document.querySelectorAll("#top-webgl .texts").forEach(($text) => {
    //   $text.addEventListener("click", () => {
    //     if (this.followingBtn.isShow || common.isMobile) {
    //       setTimeout(() => {
    //         mouse.style("default");
    //         EventBus.emit("GOTO_SINGLE_INTERVIEW", {
    //           num: this.currentNum,
    //         });
    //       }, 0);
    //     }
    //     mouse.isMousemoving = false;
    //   });
    // });

    this.mouseover = this.onMouseover.bind(this);
    this.mouseout = this.onMouseout.bind(this);

    this.mousemove = this.onMousemove.bind(this);
    this.raycaster = new THREE.Raycaster();
    this.raycasterPos = new THREE.Vector2(10000, 10000);
  }

  getCurrent() {
    const startX = Math.floor(COLUMN_NUM / 2);
    const startY = startX;

    const diffX = Math.round(-mouse.totalDiff.x / this.gridSize.x);
    const diffY = Math.round(mouse.totalDiff.y / this.gridSize.y);

    const orderX = MATH.mod(startX + diffX, COLUMN_NUM);
    const orderY = MATH.mod(startY + diffY, COLUMN_NUM);

    if (this.currentMesh) {
      this.currentMesh.userData.focus.t = 0;
      this.currentMesh.userData.hover.t = 0;
    }

    this.currentMesh = this.meshArray[orderX][orderY].group;
    this.currentMesh.userData.focus.t = 1;
    this.currentNum = this.orderArray[orderX][orderY];

    if (getter_configs("current") !== this.currentNum) {
      if (this.triggerTimer) clearTimeout(this.triggerTimer);
      setter_configs("old", getter_configs("current"));
      setter_configs("current", this.currentNum);

      this.triggerTimer = setTimeout(() => {
        if (!mouse.isMouseDown && mouse.isEnabled) {
          EventBus.emit("SHOW_TEXT");
        }
      }, 500);

      EventBus.emit("HIDE_TEXT");
    }

    background.setCurrent(this.currentNum);
  }

  createOrderImage() {
    let count = 0;
    const countArray = [];

    for (let i = 0; i < Math.round(COLUMN_NUM / 3); i++) {
      countArray[i] = 0;
    }

    for (let i = 0; i < this.$images.length; i++) {
      this.numArray.push(i);
    }

    MATH.shuffle(this.numArray);
    for (let i = 0; i < COLUMN_NUM; i++) {
      this.orderArray[i] = [];
      for (let j = 0; j < COLUMN_NUM; j++) {
        let int = Math.floor(j / 3);
        this.orderArray[i][j] = this.numArray[countArray[int]];
        countArray[int] = countArray[int] + 1;
        if (countArray[int] > this.$images.length - 1) {
          countArray[int] = 0;
        }
        count++;
      }
    }
  }

  onMouseover(event) {}

  onMouseout(event) {}

  onMousemove(event) {
    this.raycasterPos.set(
      (event.clientX / common.dimensions.x) * 2 - 1,
      -((event.clientY / common.dimensions.y) * 2 - 1)
    );
  }

  mouseEnable() {
    this.$targetBox.addEventListener("mouseover", this.mouseover);
    this.$targetBox.addEventListener("mouseout", this.mouseout);
    document.body.addEventListener("mousemove", this.mousemove);
  }

  mouseDisable() {
    this.$targetBox.removeEventListener("mouseover", this.mouseover);
    this.$targetBox.removeEventListener("mouseout", this.mouseout);
    document.body.removeEventListener("mousemove", this.mousemove);
  }

  init() {
    this.$images = document.body.querySelectorAll(selector);
    this.$textBoxes = document.body.querySelectorAll(box);
    this.$targetBox = this.$textBoxes[this.$textBoxes.length - 1];

    this.createOrderImage();

    const commonUniforms = {
      uOpening: {
        value: this.opening,
      },
      uDim: {
        value: this.dim,
      },
      uResolution: {
        value: common.dimensions,
      },
      uResolution2: {
        value: common.fbo_dimensions,
      },
    };

    for (let i = 0; i < COLUMN_NUM; i++) {
      this.meshArray[i] = [];
      for (let j = 0; j < COLUMN_NUM; j++) {
        const textureNum = this.orderArray[i][j];
        const plane = new Plane(i, j, textureNum, commonUniforms);
        this.meshArray[i][j] = plane;
        this.group.add(plane.group);
      }
    }

    this.resize();
  }

  resize() {
    const data = this.$targetBox.getBoundingClientRect();
    const width = data.width;
    const height = data.height;

    this.originOffset.y = -(data.top + height / 2 - common.dimensions.y / 2);
    this.dim.set(width, height);
    this.group.position.y = this.originOffset.y;

    const margin = width * MARGIN;
    this.totalLength.x = width * COLUMN_NUM + margin * COLUMN_NUM;
    this.totalLength.y = height * COLUMN_NUM + margin * COLUMN_NUM;

    this.gridSize.set(width + margin, height + margin);

    mouse.setGrid(this.gridSize);

    for (let i = 0; i < this.meshArray.length; i++) {
      for (let j = 0; j < this.meshArray[i].length; j++) {
        this.meshArray[i][j].resize(width, height);
      }
    }
  }

  update(camera) {
    // this.lookAtTargetVec.set(
    //   mouse.hoverPos.x, //Math.max(-this.dim.x/2, Math.min(this.dim.x/2, mouse.hoverPos.x)),
    //   mouse.hoverPos.y, //Math.max(-this.dim.y/2, Math.min(this.dim.y/2,mouse.hoverPos.y)),
    //   this.gridSize.x * 5);

    // console.log(this.lookAtTargetVec);

    this.followingBtn.update();

    for (let i = 0; i < this.meshArray.length; i++) {
      for (let j = 0; j < this.meshArray[i].length; j++) {
        this.meshArray[i][j].update(this.totalLength);
        if (animation.opening.sliding == 1) {
          this.restrictPosition(this.meshArray[i][j].group.position);
        }
      }
    }

    this.opening.x = animation.opening.panel;

    if (this.group.children.length > 0) {
      this.getCurrent();
    }

    this.raycaster.setFromCamera(this.raycasterPos, camera);

    if (this.currentMesh) {
      const intersects = this.raycaster.intersectObject(this.currentMesh);

      if (intersects.length > 0) {
        if (mouse.isMouseDown && mouse.isMousemoving) {
          this.followingBtn.hide();
        } else {
          this.followingBtn.show();
        }
        intersects[0].object.parent.userData.hover.t = 1;
      } else {
        this.followingBtn.hide();
      }
    }

    for (let i = 0; i < this.meshArray.length; i++) {
      for (let j = 0; j < this.meshArray[i].length; j++) {
        this.meshArray[i][j].updateHover();
      }
    }
  }

  restrictPosition(position) {
    let _x = position.x + this.totalLength.x / 2;
    let _y = position.y + this.totalLength.y / 2;
    _x /= this.totalLength.x;
    _y /= this.totalLength.y;

    _x = _x - Math.floor(_x);
    _y = _y - Math.floor(_y);
    _x -= 0.5;
    _y -= 0.5;

    position.x = _x * this.totalLength.x;
    position.y = _y * this.totalLength.y;
  }
}
