import * as THREE from "three";
import common from "./Common";
import assets from "./Assets";

import vertexShader from "./glsl/particle.vert";
import fragmentShader from "./glsl/particle.frag";

import { getter_configs, setter_configs } from "./status";
import mouse from "./Mouse";
import animation from "./Animation"


export default class Particles{
  constructor(){
    this.group = new THREE.Group();
    this.num =250;
  }

  init(){
    const originalG = new THREE.PlaneGeometry(1, 1);

    const iG = new THREE.InstancedBufferGeometry();
    iG.instanceCount = this.num;

    const vertices = originalG.attributes.position.clone();
    iG.setAttribute("position", vertices  );

    const uvs = originalG.attributes.uv.clone();
    iG.setAttribute("uv", uvs  );
    

    if(originalG.index){
        const indices =originalG.index.clone();
        iG.setIndex(indices);
    }

    const translates = this.setInstancedBufferAttribute(3, iG, 'aTranslate');
    const randoms = this.setInstancedBufferAttribute(3, iG, 'aRandom');
    const uvOffsets = this.setInstancedBufferAttribute(1, iG, 'aUvOffset');


    for(let i = 0; i < this.num; i++){
      translates.setXYZ(i, (Math.random() - 0.5) * 2, (Math.random() - 0.5) * 2, 0.0);
      randoms.setXYZ(i, Math.random(), Math.random(), Math.random());
      uvOffsets.setX(i, Math.floor(Math.random() * 4) / 4);
    }

    this.uniforms = {
      uTime: {
        value: 0
      },
      uResolution: {
        value: common.dimensions
      },
      uColor1: {
        value: new THREE.Color(0xfdc130)
      },
      uColor2: {
        value: new THREE.Color(0xffe39e)
      },
      uColor3: {
        value: new THREE.Color(0x916700)
      },
      uColor4: {
        value: new THREE.Color(0xb86800)
      },
      uTexture: {
        value: assets.textures.spark.value
      },
      uHoverPos2: {
        value: mouse.hoverPos2
      },
      uHoverPos3: {
        value: mouse.hoverPos3
      },
      uMousePos: {
        value: mouse.totalDiff2
      },
      uOpacity: {
        value: animation.opening.panel
      }
    }

    const m = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: this.uniforms,
      depthTest: false,
      transparent: true,
      blending: THREE.AdditiveBlending
    });

    this.mesh = new THREE.Mesh(iG, m);
    this.mesh.renderOrder = -1;
    this.group.add(this.mesh);
  }

  setInstancedBufferAttribute = (count, g, name) => {
    const ba = new THREE.InstancedBufferAttribute(new Float32Array(this.num * count), count, false );
    g.setAttribute(name, ba);
    return ba;
  }

  resize(){
  }

  update(){
    if(this.uniforms){
      this.uniforms.uTime.value += common.delta;
      this.uniforms.uOpacity.value = animation.opening.panel
    }
  }
}