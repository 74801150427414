import * as THREE from "three";
import common from "./Common";
import gsap from "gsap"
import mouse from "./Mouse";


export default class FollowingBtn{
  constructor(){
    this.$target = document.body.querySelector(".parts-circle-cursor");

    this.isShow = false;

    this.createShowTl();
  }

  createShowTl(){
    this.tl = gsap.timeline({paused: true});

    this.tl
    .to(this.$target, 0.3, {opacity: 1, scale: 1})
  }

  show(){
    if(!this.isShow){
      this.tl.play();
      mouse.style("pointer")
    }
    
    this.isShow = true
  }

  hide(){
    if(this.isShow){
      this.tl.reverse();
      mouse.style("grab")
    }
    this.isShow = false
  }

  update(){
    gsap.set(this.$target, {
      x: mouse.hoverPos.x,
      y: -mouse.hoverPos.y
    });

    // console.log(mouse.hoverPos.x + common.dimensions.x / 2, -mouse.hoverPos.y + common.dimensions.y / 2);

  }
}