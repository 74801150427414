import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { GSDevTools } from "gsap/GSDevTools";
import { SplitText } from "gsap/SplitText";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { MotionPathHelper } from "gsap/MotionPathHelper";
import { CustomEase } from "gsap/CustomEase";
import ScrollBooster from "scrollbooster";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";

gsap.registerPlugin(
  ScrollTrigger,
  DrawSVGPlugin,
  ScrollToPlugin,
  GSDevTools,
  SplitText,
  MotionPathPlugin,
  MotionPathHelper,
  CustomEase
);

export class InterviewDetailsPage {
  constructor() {
    this.$el = "[interviewdetail-page]";
  }
  init() {
    if ($(this.$el)) {
      // this.interviewCardMarquee();
      this.openingAnimeSet();
      this.openingAnime();
      this.firstViewAnime();
      // console.log("window timeline", window.tl);
    }
  }
  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");
    gsap.set(".top__img-box", {
      opacity: 0,
    });
    gsap.set(".top__embodiment .zabuton-line__text, .top__embodiment .zabuton-line__line", {
      clipPath: "inset(0 100% 0 0)",
    });
    gsap.set(".top__comment .zabuton-line__line", {
      clipPath: "inset(0 100% 0 0)",
    });
    gsap.set(".top__comment .zabuton-line__text", {
      clipPath: "inset(0 100% 0 0)",
    });
    gsap.set(".top__img-box", {
      scale: 1.1,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");

    const tl = gsap.timeline();
    tl.set(".top", {
      opacity: 1,
    });
    tl.to(
      ".top__img-box",
      {
        duration: 1,
        opacity: 1,
      },
      "show"
    );
    tl.to(
      ".top__img-box",
      {
        duration: 1.4,
        scale: 1,
        ease: "power2",
      },
      "show+=0.2"
    );
    tl.add(window.tl["topTitleHorizontal"], "-=0.3");
    // tl.to(".top__embodiment", {
    //   clipPath: "inset(0 0% 0 0)",
    //   duration: 1,
    // },'embodiment');
    // tl.to(".top__embodiment span", {
    //   clipPath: "inset(0 0% 0 0)",
    //   duration: 1,
    // },'embodiment+=0.3');
    tl.to(
      ".top__embodiment .zabuton-line__line, .top__embodiment .zabuton-line__text",
      {
        clipPath: "inset(0 0% 0 0)",
        duration: 1.2,
        ease: "power2.out",
        stagger: {
          each: 0.2,
          from: "end",
        },
      },
      "zabuton-=0.5"
    );

    tl.to(
      ".top__comment .zabuton-line__line",
      {
        clipPath: "inset(0 0% 0 0)",
        ease: "power3.out",
        duration: 1.2,
        stagger: 0.2,
      },
      "zabuton"
    );
    tl.to(
      ".top__comment .zabuton-line__text",
      {
        clipPath: "inset(0 0% 0 0)",
        ease: "power3.out",
        duration: 1.2,
        stagger: 0.2,
      },
      "zabuton+=0.3"
    );
    window.openingTimeline = tl;
  }
  firstViewAnime() {
    const wh = window.innerHeight;
    gsap.to(".top__img", {
      y: spOnly ? 0 : "100%",
      ease: "none",
      scrollTrigger: {
        trigger: ".container",
        start: "top top",
        end: `top+=${wh * 1.5} top`,
        scrub: true,
      },
    });
  }
}
