import * as THREE from 'three';
import common from "./Common";

import HBlur from "./HBlur";
import VBlur from "./VBlur";

export default class Blur{
  constructor(texture){
    this.texture = texture;
    this.image = this.texture.image;
    this.blurRadius = 20;
    this.dimensions = new THREE.Vector2(this.image.naturalWidth, this.image.naturalHeight);
    this.aspect = this.dimensions.x / this.dimensions.y;

    this.camera = new THREE.Camera();
    this.init();
  }

  init(){
    this.setGaussianBlur();

    this.planeGeometry = new THREE.PlaneBufferGeometry(2, 2);

    this.hblur = new HBlur(this.weight, this.blurRadius, this.planeGeometry);
    this.vblur = new VBlur(this.weight, this.blurRadius, this.planeGeometry);

    this.fbos = [
      new THREE.WebGLRenderTarget(this.dimensions.x, this.dimensions.y),
      new THREE.WebGLRenderTarget(this.dimensions.x, this.dimensions.y)
    ]
  }

  setGaussianBlur(){
    this.weight = [];
    var t = 0.0;

    for(let i = this.blurRadius - 1; i >= 0; i--){
      var r = 1.0 + 2.0 * i;
      var w = Math.exp(-0.5 * (r * r) / (this.blurRadius * this.blurRadius));
      this.weight.push(w);
      if(i > 0){w *= 2.0;}
      t += w;
    }

    for(let i = 0; i < this.weight.length; i++){
      this.weight[i] /= t;
    }
  }

  update(){
    this.hblur.render(this.texture, this.dimensions);
    common.renderer.setRenderTarget(this.fbos[0]);
    common.renderer.render(this.hblur.mesh, this.camera);

    this.vblur.render(this.fbos[0].texture, this.dimensions);
    common.renderer.setRenderTarget(this.fbos[1]);
    common.renderer.render(this.vblur.mesh, this.camera);

    return this.fbos[1].texture
  }
}