import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import barba from "@barba/core";
import webglMain from "./webgl/webgl-main.js";
import loading from "./loading/loading.js";
import { Common } from "./pages/_common.js";
import { TopPage } from "./pages/_index.js";
import { InterviewPage } from "./pages/_interview.js";
import { InterviewDetailsPage } from "./pages/_interview-details.js";
import { MessagePage } from "./pages/_message.js";
import { IdentityPage } from "./pages/_identity.js";
import { BusinessPage } from "./pages/_business.js";
import { JobPage } from "./pages/_job.js";
import { FaqPage } from "./pages/_faq.js";
import { CulturePage } from "./pages/_culture.js";
import { CareerPage } from "./pages/_career.js";
import { NewsPage } from "./pages/_news.js";
import { GraduatesPage } from "./pages/_graduates.js";

import { $, $$, cssVal, spOnly } from "./_global.js";
import EventBus from "./webgl/event-bus.js";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

class App {
  constructor() {
    this.webglToggle();

    this.common = new Common();
    this.common.init();
    this.common.headerToggle();

    this.topPage = new TopPage();
    this.topPage.init();

    this.interviewPage = new InterviewPage();
    this.interviewPage.init();

    this.interviewDetailsPage = new InterviewDetailsPage();
    this.interviewDetailsPage.init();

    this.messagePage = new MessagePage();
    this.messagePage.init();

    this.identityPage = new IdentityPage();
    this.identityPage.init();

    this.businessPage = new BusinessPage();
    this.businessPage.init();

    this.jobPage = new JobPage();
    this.jobPage.init();

    this.faqPage = new FaqPage();
    this.faqPage.init();

    this.culturePage = new CulturePage();
    this.culturePage.init();

    this.careerPage = new CareerPage();
    this.careerPage.init();

    this.newsPage = new NewsPage();
    this.newsPage.init();

    this.graduatesPage = new GraduatesPage();
    this.graduatesPage.init();

    barba.init({
      timeout: 3000,
      prevent: (e) => {
        if (e.el.classList.contains("ab-item")) {
          return true;
        }
      },
      transitions: [
        {
          name: "default-transition",
          leave: async (data) => {
            if (sessionStorage.getItem("webgl") !== "done") {
              $(".base-webgl__skip-button").click();
              sessionStorage.setItem("webgl", "done");
            }
            // this.$(".base-header").removeAttribute("style");

            this.common.headerMenuClose();
            ScrollTrigger.getAll().forEach((item) => {
              item.kill();
            });
            $("html").removeAttribute("orange-back");
            $("html").removeAttribute("in-faq");
            const tl = gsap.timeline();
            await tl.to(data.current.container, {
              duration: 0.5,
              opacity: 0,
            });
          },
          beforeEnter: (data) => {
            const tl = gsap.timeline();
            tl.set(data.next.container, {
              opacity: 0,
            });
            tl.call(() => {
              data.current.container.remove();
            });
            this.allOpeningAnimeSet();
            this.common.windowTlAdd();
          },
          enter: (data) => {
            window.scrollTo(0, 0);
            const tl = gsap.timeline();
            tl.to(data.next.container, {
              duration: 0.5,
              opacity: 1,
            });
            tl.call(() => {
              // sessionストレージにwebglがある場合
              if (sessionStorage.getItem("webgl") === "done") $(".base-webgl__skip-button").click();

              this.common.init();
              this.allInit();
              this.allOpeningAnimePlay();
            });
          },
        },
      ],
    });
  }
  allInit() {
    this.topPage.init();
    this.interviewPage.init();
    this.interviewDetailsPage.init();
    this.messagePage.init();
    this.identityPage.init();
    this.messagePage.init();
    this.businessPage.init();
    this.jobPage.init();
    this.faqPage.init();
    this.culturePage.init();
    this.careerPage.init();
    this.newsPage.init();
    this.graduatesPage.init();
    ScrollTrigger.refresh();
  }
  allOpeningAnimeSet() {
    this.topPage.openingAnimeSet();
    this.interviewPage.openingAnimeSet();
    this.interviewDetailsPage.openingAnimeSet();
    this.messagePage.openingAnimeSet();
    this.identityPage.openingAnimeSet();
    this.messagePage.openingAnimeSet();
    this.businessPage.openingAnimeSet();
    this.jobPage.openingAnimeSet();
    this.faqPage.openingAnimeSet();
    this.culturePage.openingAnimeSet();
    this.careerPage.openingAnimeSet();
    this.newsPage.openingAnimeSet();
    this.graduatesPage.openingAnimeSet();
  }
  allOpeningAnimePlay() {
    this.topPage.openingAnime();
    this.interviewPage.openingAnime();
    this.interviewDetailsPage.openingAnime();
    this.messagePage.openingAnime();
    this.identityPage.openingAnime();
    this.messagePage.openingAnime();
    this.businessPage.openingAnime();
    this.jobPage.openingAnime();
    this.faqPage.openingAnime();
    this.culturePage.openingAnime();
    this.careerPage.openingAnime();
    this.newsPage.openingAnime();
    this.graduatesPage.openingAnime();
  }
  deleteUnnecessaryEl() {
    if ($(".base-tutorial")) $(".base-tutorial").remove();
    if ($(".base-loading")) $(".base-loading").remove();
  }
  webglToggle() {
    if (location.search.match(/no-webgl/)) {
      sessionStorage.setItem("webgl", "done");
      this.deleteUnnecessaryEl();
    }

    if (navigator.userAgent.match(/googlebot/i)) {
      sessionStorage.setItem("webgl", "done");
      this.deleteUnnecessaryEl();
    }

    const throwAttrs = ["[error-page]", "[interviewdetail-page]", "[newsdetails-page]"];
    throwAttrs.forEach((el) => {
      if ($(el)) {
        sessionStorage.setItem("webgl", "done");
      }
    });

    EventBus.on("GOTO_SINGLE_INTERVIEW", ({ detail }) => {
      $(".top-webgl .parts-circle-link").click();
      const tl = gsap.timeline();
      tl.to(".container", {
        opacity: 0,
        duration: 0.5,
      });
      tl.call(() => {
        const interview = $$(".texts")[detail.num].getAttribute("link-to");
        window.location.href = `/interview/${interview}`;
      });
    });

    const session = sessionStorage.getItem("webgl");
    webglMain();

    if ($("#top-webgl") === null) {
      if (window.scrollSmootherBody) {
        window.scrollSmootherBody.paused(false);
      }
      this.allOpeningAnimeSet();
      this.allOpeningAnimePlay();
      $("body").style.overflow = "visible";
      return;
    } else {
      $(".container").setAttribute("index-float", "");
    }
    if (window.scrollSmootherBody) {
      window.scrollSmootherBody.paused(true);
    }

    if (session === "done") {
      $("body").style.overflow = "visible";
      $(".container").removeAttribute("index-float");
      gsap.set("#top-webgl, .base-loading", {
        opacity: 0,
        pointerEvents: "none",
      });
      if ($(".base-tutorial")) $(".base-tutorial").style.display = "none";
      this.deleteUnnecessaryEl();
    } else {
      loading();
      setTimeout(() => {
        $("body").style.overflow = "hidden";
      }, 2000);
    }

    // webgl閉じる動作

    $(".base-webgl__skip-button").addEventListener("click", () => {
      console.log("webgl閉じる");
      if (window.openingTimeline) window.openingTimeline.play();
      if (window.scrollSmootherBody) window.scrollSmootherBody.paused(false);
      this.deleteUnnecessaryEl();
      $("body").style.overflow = "visible";

      sessionStorage.setItem("webgl", "done");

      $(".container").removeAttribute("index-float");
      EventBus.emit("STOP_WEBGL");
      this.allOpeningAnimeSet();

      if (window.openingTimeline) window.openingTimeline.progress(0);

      const tl = gsap.timeline();
      tl.to("#top-webgl", {
        opacity: 0,
        pointerEvents: "none",
        duration: 0.4,
      });
      tl.call(() => {
        if (spOnly) return;
        ScrollTrigger.refresh();
        console.log("ScrollTrigger refresh timing");
        window.addEventListener("resize", () => {
          ScrollTrigger.refresh();
        });
        if (window.scrollSmootherBody) {
          ScrollTrigger.refresh();
        }
        window.srf = () => {
          ScrollTrigger.refresh();
        };

        // 2000msごとにリフレッシュ
        setInterval(() => {
          if ($(".container").getBoundingClientRect().top !== 0) return;
          console.log("リフレッシュします");
          gsap.set(window, {
            scrollTo: 0,
          });

          ScrollTrigger.refresh();
          ScrollTrigger.update();
        }, 1000);
      });
    });

    /// webgl開く動作
    $(".base-header__menu__experience").addEventListener("click", () => {
      $(".base-header__menu-toggle").click();
      gsap.set("#top-webgl", {
        opacity: 1,
        pointerEvents: "auto",
      });
      if (window.scrollSmootherBody) window.scrollSmootherBody.paused(true);
      $(".container").setAttribute("index-float", "");
      $("body").style.overflow = "hidden";
      EventBus.emit("START_WEBGL");
    });
  }
}

window.addEventListener("DOMContentLoaded", () => {
  new App();
});
