import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";
import Utils from "../_utils.js";
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export class CareerPage {
  constructor() {
    this.$el = "[career-page]";
  }
  init() {
    if ($(this.$el)) {
      this.openingAnimeSet();
      this.openingAnime();
      // this.selectIntroductionImage();
      this.selectSkilsImage();
      const utils = new Utils();
      utils.changeOrange({
        section: ".container",
        trigger: ".contents-wrap",
      });
      this.contentsWindowOpen();
      // console.log("window timeline", window.tl);
    }
  }

  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");

    gsap.set(".contents, .top__message", {
      opacity: 0,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");
    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.to(".contents, .top__message", {
      opacity: 1,
      stagger: 0.1,
      duration: 1,
    });
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
  selectSkilsImage() {
    $$(".skil__contents__graph-button").forEach((el) => {
      el.addEventListener("click", (e) => {
        $$(".skil__contents__graph-button").forEach((buttonEl) => {
          buttonEl.removeAttribute("active-graph");
        });
        el.setAttribute("active-graph", "");
        const attr = e.target.getAttribute("career-graph");
        console.log(attr);
        $$(".skill__contents__graph-img").forEach((el) => {
          el.removeAttribute("active-graph");
          if (el.getAttribute("career-graph") === attr) {
            el.setAttribute("active-graph", "");
          }
        });

        // const text = e.target.textContent;
        // $(".skil__contents").setAttribute("skil-active", text === "Hair Make" ? "hair" : "nail");
        // $(".skil__contents__graph").setAttribute(
        //   "career-graph",
        //   text === "Hair Make" ? "hair" : "nail"
        // );
      });
    });
  }
  contentsWindowOpen() {
    if (spOnly) return;
    this.careerTarget = null;
    const svgObj = {
      hair: ".career_hair",
      nail: ".career_nail",
      step: ".career_step",
    };
    const toggle = () => {
      $(".contents-window").toggleAttribute("window-open");
      const scrollY = window.pageYOffset;
      $(".contents-window").style.top = `${scrollY}px`;
    };
    $$("[career-graph]").forEach((el) => {
      el.addEventListener("click", (target) => {
        this.careerTarget = el.getAttribute("career-graph");
        // console.log(this.careerTarget);
        toggle();
      });
    });

    $(".contents-window").addEventListener("click", () => {
      toggle();
    });
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "attributes") {
          const judge = mutation.target.getAttribute("window-open") === null;
          if (window.scrollSmootherBody) window.scrollSmootherBody.paused(!judge);

          const tl = gsap.timeline();
          // console.log(this.careerTarget);

          if (!judge) {
            // console.log("開いた");
            tl.set(".contents-window img", {
              opacity: 0,
            });
            tl.set(`[career-contents=${this.careerTarget}]`, {
              opacity: 1,
              duration: 0.6,
            });
          }

          tl.to(".base-header", {
            opacity: judge ? 1 : 0,
            duration: 0.5,
          });
        }
      });
    });
    observer.observe($(".contents-window"), {
      attributes: true,
    });
  }
}
