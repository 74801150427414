import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { $, $$, cssVal, scaleClip, spOnly } from "./_global.js";
gsap.registerPlugin(ScrollTrigger);
export default class App {
  constructor() {}
  changeOrange(option) {
    // const option = {
    //   section: ".top, .article, .introduction",
    //   trigger: ".article__box",
    //   leaveToggle: false,
    // };
    const orange = cssVal("--color-orange-100");
    const white = cssVal("--color-white-200");
    const black = cssVal("--color-black-100");
    const orangeChange = () => {
      $("html").setAttribute("orange-back", "");
      gsap.to(option.section, {
        backgroundColor: orange,
        duration: 0.5,
        color: white,
      });
      if ($("[change-inherit-color]")) {
        gsap.to("[change-inherit-color]", {
          duration: 0.5,
          stroke: white,
          borderColor: white,
        });
      }
    };
    const blackChange = () => {
      $("html").removeAttribute("orange-back", "");
      gsap.to(option.section, {
        backgroundColor: white,
        duration: 0.5,
        color: black,
      });
      if ($("[change-inherit-color]")) {
        gsap.to("[change-inherit-color]", {
          duration: 0.5,
          stroke: white,
          borderColor: white,
        });
      }
      // console.log($$("[change-inherit-color]"));
    };
    ScrollTrigger.create({
      trigger: option.trigger,
      start: `top center-=150`,
      end: `bottom center-=150`,
      // markers: true,
      onEnter: () => {
        orangeChange();
      },
      onLeaveBack: () => {
        $("html").removeAttribute("orange-back", "");
        blackChange();
      },
      onLeave: () => {
        if (option.leaveAction === "off") return;
        $("html").removeAttribute("orange-back", "");
        blackChange();
      },
      onEnterBack: () => {
        if (option.leaveAction === "off") return;
        orangeChange();
      },
    });
  }
}
