import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollBooster from "scrollbooster";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";

// gsap.registerPlugin(ScrollTrigger);

export class NewsPage {
  constructor() {
    this.$el = "[news-page]";
  }
  init() {
    if ($(this.$el)) {
      this.openingAnimeSet();
      this.openingAnime();
      // console.log("window timeline", window.tl);
    }
    if ($("[newsdetails-page]")) {
      this.allTopBackDirection();
    }
  }

  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");

    gsap.set(".news", {
      opacity: 0,
      y: 20,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");
    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.to(
      ".news",
      {
        opacity: 1,
        y: 0,
        stagger: 0.1,
        duration: 1,
      },
      "lead"
    );
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
  allTopBackDirection() {
    gsap.set(".return__to-top__circle-black path", {
      drawSVG: "0%",
      rotate: "90deg",
      transformOrigin: "50% 50%",
    });
    $(".return__to-top").addEventListener("mouseenter", () => {
      gsap.to(".return__to-top__circle-black path", {
        drawSVG: "100%",
        rotate: "270deg",
        duration: 1.2,
        ease: "haruka1.inOut",
        transformOrigin: "50% 50%",
      });
    });
    $(".return__to-top").addEventListener("mouseleave", () => {
      gsap.to(".return__to-top__circle-black path", {
        drawSVG: "0%",
        rotate: "90deg",
        duration: 1.2,
        ease: "haruka1.inOut",
        transformOrigin: "50% 50%",
      });
    });
  }
}
