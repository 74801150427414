import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { GSDevTools } from "gsap/GSDevTools";
import { SplitText } from "gsap/SplitText";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { MotionPathHelper } from "gsap/MotionPathHelper";
import { CustomEase } from "gsap/CustomEase";
import ScrollBooster from "scrollbooster";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";
import Utils from "../_utils.js";

gsap.registerPlugin(
  ScrollTrigger,
  DrawSVGPlugin,
  ScrollToPlugin,
  GSDevTools,
  SplitText,
  MotionPathPlugin,
  MotionPathHelper,
  CustomEase
);

export class CulturePage {
  constructor() {
    this.$el = "[culture-page]";
  }
  init() {
    if ($(this.$el)) {
      this.openingAnimeSet();
      this.openingAnime();
      const utils = new Utils();
      utils.changeOrange({
        section: ".container",
        trigger: ".contents-wrap",
      });
      // console.log("window timeline", window.tl);
    }
  }

  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");

    gsap.set(".top__text, .top__text--en, .career", {
      opacity: 0,
      y: 20,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");
    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.to(
      ".top__text,  .career",
      {
        opacity: 1,
        y: 0,
        stagger: 0.1,
        duration: 1,
      },
      "top"
    );
    tl.to(
      ".top__text--en",
      {
        opacity: 0.6,
        y: 0,
        duration: 1,
      },
      "top"
    );
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
}
