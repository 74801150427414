import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";
import Utils from "../_utils.js";

gsap.registerPlugin(ScrollTrigger);

export class JobPage {
  constructor() {
    this.$el = "[job-page]";
  }
  init() {
    if ($(this.$el)) {
      // this.interviewCardMarquee();
      this.openingAnimeSet();

      this.openingAnime();
      const utils = new Utils();
      utils.changeOrange({
        section: ".container",
        trigger: ".search",
        leaveAction: "off",
      });
      this.filterJob();
      this.observeStoresArea();
    }
  }

  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");

    gsap.set(".top__content, .top__lead", {
      opacity: 0,
      y: 30,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");

    tl.to(
      ".top__content, .top__lead",
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
      },
      "lead"
    );
    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
  filterJob() {
    gsap.set(".search__area-list, .search__stores-list, .search__line", {
      opacity: 0,
      display: "none",
    });
    this.originList = $$(".search__store-list");
    this.stepTwoButtons = $$(".search__button-list--area .search__button");
    this.filterItemOccupation = "";
    this.filterItemArea = "";
    $$(".search__button-list--occupation .search__button").forEach((item) => {
      item.addEventListener("click", (e) => {
        $$(".search__button-list--occupation .search__button").forEach((el) => {
          el.removeAttribute("select-active");
        });
        $$(".search__button-list--area .search__button").forEach((el) => {
          el.removeAttribute("select-active");
        });

        $$(".search__store-list").forEach((el) => {
          el.removeAttribute("filter-active");
          el.removeAttribute("filter-active-job");
        });
        this.filterItemArea = "";

        // setTimeout(() => {
        e.target.setAttribute("select-active", "");

        // step2をフィルター
        this.filterItemOccupation = e.target.textContent;

        // console.log("職種を選択:" + this.filterItemOccupation);

        filterItem();

        const jobSelectList = $$("[filter-active-job]");

        // console.log(jobSelectList);

        const areaList = Array.from(jobSelectList).map((item) => {
          return item.getAttribute("filter-area");
        });

        const areaListUnique = Array.from(new Set(areaList));

        // console.log(areaListUnique);

        let areaCount = 0;
        this.stepTwoButtons.forEach((item) => {
          if (areaListUnique.includes(item.textContent)) {
            item.style.display = "";
            areaCount++;
          } else {
            item.style.display = "none";
          }
        });
        if (areaCount === 0) {
          $(".search__button--notice").style.display = "";
        }

        const tl = gsap.timeline();
        tl.set(".search__stores-list , .search__area-list+.search__line", {
          opacity: 0,
          display: "none",
        });
        tl.set(".search__area-list , .search__occupation-list+.search__line", {
          display: "",
        });
        tl.to(".search__area-list , .search__occupation-list+.search__line", {
          opacity: 1,
          duration: 0.5,
          delay: 0.2,
        });
        // }, 3000);
      });
    });

    $$(".search__button-list--area .search__button").forEach((item) => {
      item.addEventListener("click", (e) => {
        this.filterItemArea = e.target.textContent;
        // console.log("場所は:" + this.filterItemArea);
        filterItem();
        const tl = gsap.timeline();

        $$(".search__button-list--area .search__button").forEach((el) =>
          el.removeAttribute("select-active")
        );
        e.target.setAttribute("select-active", "");

        tl.set(".search__stores-list , .search__area-list+.search__line", {
          display: "",
        });
        tl.to(".search__stores-list , .search__area-list+.search__line", {
          opacity: 1,
          duration: 0.5,
          delay: 0.2,
        });
      });
    });

    const filterItem = () => {
      const originList = Array.from(this.originList);

      const occupationArray = originList.filter((item) => {
        return item.getAttribute("filter-job") === this.filterItemOccupation;
      });
      const areaArray = originList.filter((item) => {
        return item.getAttribute("filter-area") === this.filterItemArea;
      });

      const commonArray = occupationArray.filter((item) => {
        return areaArray.includes(item);
      });

      originList.forEach((item) => {
        item.setAttribute("filter-active", "");
        item.removeAttribute("filter-active-job", "");
      });
      occupationArray.forEach((item) => {
        item.setAttribute("filter-active-job", "");
      });
      commonArray.forEach((item) => {
        item.removeAttribute("filter-active", "");
        item.removeAttribute("filter-active-job", "");
      });
    };
  }
  observeStoresArea() {
    if (spOnly) return;

    const storesPin = () => {
      ScrollTrigger.create({
        trigger: ".search__stores-list",
        start: "top top+=200",
        end: "bottom bottom-=20%",
        pin: ".search__stores-list .search__step-box",
        id: "storesPin",
      });
    };

    const target = $(".search__stores-list");

    let currentHeight = target.clientHeight;

    $$(".search__button").forEach((item) => {
      item.addEventListener("click", () => {
        if (ScrollTrigger.getById("storesPin")) {
          ScrollTrigger.getById("storesPin").kill();
        }
      });
    });
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const newHeight = entry.target.clientHeight;
        if (newHeight !== currentHeight) {
          const targetHeight = Math.round(target.getBoundingClientRect().height);
          const wh = window.innerHeight;

          target.setAttribute("overflow-height", "");
          // --custom-padding-topというcss変数を定義、targetに
          const stepBoxElTop = $(".search__area-list .search__step-box").getBoundingClientRect().y;
          const areaListElTop = $(".search__area-list").getBoundingClientRect().y;
          const paddingTop = Math.round(stepBoxElTop - areaListElTop);
          target.style.setProperty("--custom-padding-top", `${paddingTop}px`);

          if (targetHeight * 1.2 > wh) {
            storesPin();
          } else {
            target.removeAttribute("overflow-height");
          }

          currentHeight = newHeight;
        }
      });
    });

    observer.observe(target);
  }
}
