import * as THREE from "three";
import EventBus from "../event-bus";
import common from "./Common";

import { getter_configs, setter_configs } from "./status";

class Mouse{
  constructor(){
    this.pos = new THREE.Vector2();
    this.diff = new THREE.Vector2();

    this.mousemoveTimer = null
    this.isMouseDown = false;
    this.isWheeling = false;

    this._totalDiff = new THREE.Vector2();
    this.totalDiff = new THREE.Vector2();
    this.totalDiff2 = new THREE.Vector2();


    this.gridSize = new THREE.Vector2();

    this.wheelDelta = new THREE.Vector2();
    this._wheelTotal = new THREE.Vector2();
    this.wheelTotal = new THREE.Vector2();

    this._hoverPos = new THREE.Vector2();
    this.hoverPos = new THREE.Vector2();
    this.hoverPos2 = new THREE.Vector2();
    this.hoverPos3 = new THREE.Vector2();

    this.wheelTimer = null

    this.mouseTimer = null;

    this.isEnabled = false;

    this.isMousemoving = false;
  }

  enable(){
    this.isEnabled = true;
    this.show();
  }

  disable(){
    this.isEnabled = false;
    this.hide();
  }

  init(){
    this.onMousemove = this.mousemove.bind(this); 
    this.onclick = this.click.bind(this);
    this.onMousedown = this.mousedown.bind(this)

    this.onTouchstart = this.touchstart.bind(this);
    this.onTouchmove = this.touchmove.bind(this)
    this.onTouchend = this.touchend.bind(this);

    this.onWheel = this.wheel.bind(this);

    this.onHover = this.hover.bind(this);

    this.$canvas = common.renderer.domElement
  }

  show(){
    this.$canvas.addEventListener("click", this.onclick);
    this.$canvas.addEventListener("mouseout", this.onclick);
    this.$canvas.addEventListener("mousedown", this.onMousedown);

    this.$canvas.addEventListener("touchstart", this.onTouchstart);
    this.$canvas.addEventListener("touchend", this.onTouchend);

    document.body.addEventListener("wheel", this.onWheel);

    this.$canvas.addEventListener("mousemove", this.onHover);
  }

  hide(){
    this.$canvas.removeEventListener("click", this.onclick);
    this.$canvas.removeEventListener("mouseout", this.onclick);
    this.$canvas.removeEventListener("mousedown", this.onMousedown);

    this.$canvas.removeEventListener("touchstart", this.onTouchstart);
    this.$canvas.removeEventListener("touchend", this.onTouchend);

    document.body.removeEventListener("wheel", this.onWheel);

    this.$canvas.removeEventListener("mousemove", this.onHover);

  }

  wheel(event){
    this.isWheeling = true;
    if(this.wheelTimer){
      clearTimeout(this.wheelTimer);
    }
    this.wheelDelta.set(-event.deltaX * 0.1, -event.deltaY * 0.1);
    this._totalDiff.add(this.wheelDelta);

    this.wheelTimer = setTimeout(() => {
      this.isWheeling = false;
      EventBus.emit("SHOW_TEXT");
      this.adjust();
    }, 300);
  }

  mousedown(){
    // console.log("mousedown");
    this.in();
    this.$canvas.addEventListener("mousemove", this.onMousemove);
  }

  touchstart(){
    this.in();
    this.$canvas.addEventListener("touchmove", this.onTouchmove);
  }

  in(){
    console.log();
    this.isFirst = true;
    this.isMouseDown = true;
  }

  click(){
    this.out();
    this.$canvas.removeEventListener("mousemove", this.onMousemove);
    
  }

  touchend(){
    this.out();
    this.$canvas.removeEventListener("touchmove", this.onTouchmove);
  }

  style(name){
    // console.log(name);
    common.renderer.domElement.style.cursor = name
  }

  out(){
    this.diff.set(0, 0);
    this.isMouseDown = false;
    this.style("grab")

    this.mouseTimer = setTimeout(() => {
      EventBus.emit("SHOW_TEXT");
    }, 300);

    this.adjust();
  }

  mousemove(event){
    this.move(event.clientX, event.clientY);

    

    // console.log(this._totalDiff);
  }

  hover(event){
    // console.log("hover");
    this._hoverPos.set(event.clientX - common.dimensions.x / 2, -(event.clientY - common.dimensions.y / 2) );
  }

  touchmove(event){
    const touches = event.changedTouches;
    this.move(touches[0].pageX, touches[0].pageY);
  }

  move(x, y){
    if(this.mousemoveTimer){
      clearTimeout(this.mousemoveTimer);
    }

    this.isMousemoving = true;

    if(this.isFirst){
      this.diff.x = 0;
      this.diff.y = 0;

      EventBus.emit("HIDE_TEXT");

      this.isFirst = false;

      if(this.mouseTimer){
        clearTimeout(this.mouseTimer);
      }
      // EventBus.emit("HIDE_TEXT");

    } else {
      this.diff.x = x - this.pos.x;
      this.diff.y = y - this.pos.y;
    }

    this.pos.set(x, y);

    this._totalDiff.add(this.diff);

    this.mousemoveTimer = setTimeout(() => {
      this.diff.set(0, 0);
      // this.isMousemoving = false;
    }, 200);
  }

  adjust(){
    let x = this._totalDiff.x;
    let y = this._totalDiff.y;

    x = Math.round(x / this.gridSize.x) * this.gridSize.x;
    y = Math.round(y / this.gridSize.y) * this.gridSize.y;

    this._totalDiff.set(x, y);
  }

  setGrid(gridSize){
    this.gridSize.copy(gridSize);
  }

  resize(){
    this.adjust();
  }

  update(){
    const ease = Math.min(1.0, common.delta * 4.0);
    this.totalDiff.lerp(this._totalDiff, ease);

    const ease2 = Math.min(1.0, common.delta * 2.0);
    this.hoverPos.lerp(this._hoverPos, ease2);

    const ease2_2 = Math.min(1.0, common.delta * 0.5);
    this.totalDiff2.lerp(this._totalDiff, ease2_2);

    const ease3 = Math.min(1.0, common.delta * 1.0);
    this.hoverPos2.lerp(this._hoverPos, ease3);

    const ease4 = Math.min(1.0, common.delta * 0.5);
    this.hoverPos3.lerp(this._hoverPos, ease4);
  }
}

export default new Mouse();