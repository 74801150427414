import * as THREE from 'three';
import common from "./Common";

export default class HBlur{
  constructor(weight, blurRadius, geometry){
    this.weight = weight;
    this.blurRadius = blurRadius;
    this.geoemtry = geometry;
    this.init();
  }

  init(){
    this.material = this.createMaterial();
    this.mesh = new THREE.Mesh(this.geoemtry, this.material);
  }

  createMaterial(){
    this.uniforms =  {
        uDiffuse: { 
            value: null
        },
        h: { 
            value: 0.0
        },
        weight: { 
            value: this.weight.concat()
        },
    };
    var vertexShader = [
      "varying vec2 vUv;",
      "void main() {",
        "vUv = uv;",
        "gl_Position = vec4( position, 1.0 );",
      "}"

    ].join("\n");

    var fragmentShader = [
      "uniform sampler2D uDiffuse;",
        "uniform float h;",

        "uniform float weight[" + this.blurRadius + "];",

        "varying vec2 vUv;",

        "void main() {",
          "float count = " + this.blurRadius + ".0 - 1.0;",

          "vec4 color = vec4(0.0);",
          "vec4 sum = vec4( 0.0 );",
          "float w;",
          "float sumW = 0.0;",
          "float actualWeight;",

          // loop
          "for(int i = 0; i < " + this.blurRadius + " - 1; i++){",
            "w = weight[i];",

            "color = texture2D( uDiffuse, vec2( vUv.x - count * h, vUv.y ) );",
            "actualWeight = w;",
            "sum.rgb += color.rgb * w;",
            "sum.a += color.a * w;",
            "sumW += actualWeight;",

            "color = texture2D( uDiffuse, vec2( vUv.x + count * h, vUv.y ) );",
            "actualWeight = w;",
            "sum.rgb += color.rgb * w;",
            "sum.a += color.a * w;",
            "sumW += actualWeight;",

            "count--;",
          "}",

          "w = weight[" + this.blurRadius + " - 1];",

          "color = texture2D( uDiffuse, vec2( vUv.x, vUv.y ) );",
          "actualWeight = w ;",
          "sum.rgb += color.rgb * w;",
          "sum.a += color.a * w;",
          "sumW += actualWeight;",
          "gl_FragColor = vec4(sum.rgb / sumW, sum.a);",
        "}"

    ].join("\n");


    return new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: this.uniforms,
    });
  }

  render(input, res){
    this.uniforms.h.value = 1.0 / res.x;
    this.uniforms.uDiffuse.value = input;
  }
}