export const lerp = function(start, target, easing){
  return start + (target - start) * easing;
};

export const cubicOut = function(t) {
  const f = t - 1.0;
  return f * f * f + 1.0;
};

export const cubicIn = function(t) {
  return t * t * t;
};

export const expoOut = function(t) {
  return t == 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);
};

export const cubicInOut = function(t) {
  return t < 0.5
    ? 4.0 * t * t * t
    : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0;
}

export const getProgress = function(top, start, end){
  return Math.min(1.0, Math.max(0.0, (top - start) / (end - start)));
};

export const parabola = function(t){
  return -(2 * t - 1) * (2 * t - 1) + 1;
}

export const shuffle = function(arr){
  for(var i =arr.length-1 ; i>0 ;i--){
      var j = Math.floor( Math.random() * (i + 1) ); //random index
      [arr[i],arr[j]]=[arr[j],arr[i]]; // swap
  }
}

export const mod = function(a,b){
  let c = a % b;

  if(c < 0){
    c += b;
  }

  c = Math.abs(c);

  return c;
}