import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { GSDevTools } from "gsap/GSDevTools";
import { SplitText } from "gsap/SplitText";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { MotionPathHelper } from "gsap/MotionPathHelper";
import { CustomEase } from "gsap/CustomEase";
import ScrollBooster from "scrollbooster";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";
import Utils from "../_utils.js";

gsap.registerPlugin(
  ScrollTrigger,
  DrawSVGPlugin,
  ScrollToPlugin,
  GSDevTools,
  SplitText,
  MotionPathPlugin,
  MotionPathHelper,
  CustomEase
);

export class GraduatesPage {
  constructor() {
    this.$el = "[graduates-page]";
  }
  init() {
    if ($(this.$el)) {
      this.openingAnimeSet();
      this.openingAnime();

      const utils = new Utils();
      utils.changeOrange({
        section: ".container",
        trigger: ".article",
        leaveAction: "off",
      });

      // console.log("window timeline", window.tl);
    }
  }

  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");

    gsap.set(".top__link, .main-faq", {
      opacity: 0,
      y: 20,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");
    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.to(".top__link, .main-faq", {
      opacity: 1,
      y: 0,
      stagger: 0.1,
      duration: 1,
    });
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
  changeOrange() {
    const section = ".container";
    const trigger = ".article";
    const orange = "#ff543e";
    const white = "#F8F4F2";
    const black = "#000000";
    ScrollTrigger.create({
      trigger,
      start: "top center",
      end: "bottom center",
      // markers: true,
      onEnter: () => {
        gsap.to(section, {
          backgroundColor: orange,
          duration: 0.5,
          color: white,
        });
      },
      onLeaveBack: () => {
        gsap.to(section, {
          backgroundColor: white,
          duration: 0.5,
          color: black,
        });
      },
    });
  }
}
