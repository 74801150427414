import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";
import { CustomEase } from "gsap/CustomEase";
import { GSDevTools } from "gsap/GSDevTools";
import { $, $$, cssVal, scaleClip, spOnly, isTouchDevice } from "../_global.js";
import EventBus from "../webgl/event-bus";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText, CustomEase, GSDevTools);

export class TopPage {
  constructor() {
    this.$el = "[top-page]";
  }
  init() {
    if ($(this.$el)) {
      this.openingAnimeSet();
      this.openingAnime();
      this.firstZoomAnime();
      this.firstMessageAnime();
      this.messageDirection();
      this.identityDirection();
      this.businessInDirection();
      this.screenDirection();
      this.newsDirection();
    }
  }
  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");
    this.titleSplit = new SplitText(".top__heading__text", {
      type: "chars, lines",
      tag: "span",
      charsClass: "top__heading__text--char",
      linesClass: "top__heading__text--line",
    });
    this.topLead = new SplitText(".top__lead", {
      type: "chars,words",
      tag: "span",
      charsClass: "top__lead--char",
    });
    gsap.set(this.titleSplit[spOnly ? "lines" : "chars"], {
      y: "20%",
      opacity: 0,
      // display: "block",
      display: "inline-block",
    });

    gsap.set(this.topLead[spOnly ? "words" : "chars"], {
      x: -4,
      opacity: 0,
      display: "inline-block",
    });
    gsap.set(".top__img-window", {
      y: 20,
      opacity: 0,
    });
    // gsap.set(".top__img", {
    //   opacity: 0,
    //   scale: 1.2,
    // });
    gsap.set(".top__scroll", {
      y: "100%",
      clipPath: "inset(0% 0 100% 0)",
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    // if (window.topPageAfter) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline({
      delay: 0.5,
      onComplete: () => {
        this.scrollPartsAnime();
      },
    });
    tl.to(
      this.titleSplit[spOnly ? "lines" : "chars"],
      {
        y: 0,
        duration: 1.5,
        opacity: 1,
        ease: "power2",
        stagger: spOnly ? 0.4 : 0.06,
      },
      "title"
    );
    tl.to(
      ".top__scroll",
      {
        y: "0%",
        clipPath: "inset(0% 0 0% 0)",
        duration: 1.2,
        ease: "haruka1.inOut",
        clearProps: "all",
      },
      "lead-=1"
    );
    tl.to(
      this.topLead[spOnly ? "words" : "chars"],
      {
        x: "0%",
        opacity: 1,
        duration: 1.2,
        ease: "haruka1.inOut",
        stagger: spOnly ? 0.1 : 0.02,
      },
      "lead-=1"
    );
    tl.to(
      ".top__img-window",
      {
        y: 0,
        duration: 1.4,
        opacity: 1,
        ease: "power2",
      },
      "topImage-=1"
    );
    // tl.to(
    //   ".top__img-window",
    //   {
    //     opacity: 1,
    //     duration: 1.2,
    //     ease: "power1.in",
    //   },
    //   "topImage-=0.8"
    // );
    // tl.to(
    //   ".top__img",
    //   {
    //     y: 0,
    //     // opacity: 1,
    //     scale: 1,
    //     duration: 1.5,
    //     ease: "expo.out",
    //   },
    //   "topImage-=1"
    // );
    tl.to(
      ".top__img:first-child",
      {
        opacity: 1,
        duration: 1.5,
        ease: "haruka1.inOut",
      },
      "topImage-=1"
    );
    window.openingTimeline = tl;
  }
  scrollPartsAnime() {
    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 3,
    });
    tl.to(".top__scroll", {
      y: "-100%",
      rotateX: "90deg",
      // perspective: 300,
      duration: 0.8,
      ease: "power3.out",
    });
    tl.set(".top__scroll", {
      y: "100%",
      rotateX: "-90deg",
      // perspective: 300,
    });
    tl.to(".top__scroll", {
      y: "0%",
      rotateX: "0deg",
      duration: 0.6,
      // perspective: 0,
    });
  }
  firstZoomAnime() {
    const topImageBoxTop = $(".top__img-box").getBoundingClientRect().top;
    const windowTop = window.pageYOffset;

    const tl = gsap.timeline({
      paused: true,
      repeat: -1,
    });
    $$(".top__img").forEach((item, index) => {
      item.style.zIndex = index;
      if (index === 0) return;
      tl.to(item, {
        startAt: {
          opacity: 0,
        },
        opacity: 1,
        duration: 0.7,
        delay: 1.5,
        ease: "power1",
      });
    });

    tl.set($$(".top__img")[0], {
      zIndex: $$(".top__img").length,
      opacity: 0,
    });

    tl.play();

    const pallaxTl = gsap.timeline();
    pallaxTl.to(
      ".top__img-window",
      {
        startAt: {
          scale: spOnly ? 1 : 1.2,
          transformOrigin: "top",
        },
        scale: 1,
        ease: "none",
      },
      "show"
    );
    pallaxTl.to(
      ".top__img-box__bar",
      {
        startAt: {
          scaleX: 1,
        },
        scaleX: 0,
        ease: "none",
      },
      "show"
    );

    ScrollTrigger.create({
      trigger: ".top__img-box",
      scrub: true,
      start: `top top+=${topImageBoxTop + windowTop}`,
      end: `top top`,
      animation: pallaxTl,
      // markers: true,
    });
  }
  firstMessageAnime() {
    const rawText = $(".top__text-contents").innerHTML;
    const message = new SplitText(".top__text-contents", {
      type: "lines",
      linesClass: "top__text-contents--line",
    });
    message.lines.forEach((line, index) => {
      if (!spOnly) {
        gsap.to(line, {
          backgroundImage: "linear-gradient(100deg, #000000 0%, #000000 60%, #00000033 100%)",
          duration: 4,
          delay: 0.6,
          ease: "power3",
          scrollTrigger: {
            trigger: line,
            start: "top bottom",
          },
          onComplete: () => {
            const paddingRight = gsap.getProperty(".top__text-contents--line", "paddingRight");
            // console.log(paddingRight);
            gsap.set(".top__text-contents", {
              paddingRight: paddingRight,
            });
            $(".top__text-contents").innerHTML = rawText;
          },
        });
      }
    });

    if (spOnly) {
      gsap.to(message.lines, {
        backgroundImage: "linear-gradient(100deg, #000000 0%, #000000 60%, #00000033 100%)",
        duration: 2,
        ease: "power3",
        stagger: 0.1,
        scrollTrigger: {
          trigger: ".top__text-contents",
          start: "top bottom",
        },
      });
    }
  }
  messageDirection() {
    gsap.set(".message__kv", {
      opacity: 0,
      clipPath: scaleClip(1.1),
      scale: 1.1,
    });

    const tl = gsap.timeline();
    tl.add(window.tl.messageTitle());
    tl.add(window.tl.messageLead(), "kv-=0.3");
    tl.add(window.tl.messageDescription(), "-=2");
    tl.add(window.tl.messageLink(), "-=1");

    tl.to(
      ".message__kv",
      {
        opacity: 1,
        duration: 1.6,
        clipPath: scaleClip(1),
        scale: 1,
        ease: "power2",
        clearProps: "all",
      },
      "kv-=0.3"
    );

    ScrollTrigger.create({
      trigger: ".message__info-box",
      start: "top bottom",
      animation: tl,
    });
  }
  identityDirection() {
    const titleSplit = new SplitText(".identity__copy", { type: "chars", tag: "span" });
    gsap.set(titleSplit.chars, {
      y: "100%",
      rotateX: "60deg",
      display: "inline-block",
    });

    gsap.set(".identity__kv--front", {
      x: "-100%",
      y: "-100%",
      rotate: "0deg",
    });
    gsap.set(".identity__kv--back", {
      x: "100%",
      y: "-100%",
      rotate: "0deg",
    });
    gsap.set(".identity__kv__image", {
      opacity: 0,
    });

    const tl = gsap.timeline({
      paused: true,
    });
    tl.to(
      ".identity__kv__image",
      {
        duration: 1.2,
        ease: "power1",
        opacity: 1,
      },
      "show"
    );
    tl.to(
      ".identity__kv__image",
      {
        duration: 2,
        ease: "power3",
        scale: 1.4,
      },
      "show"
    );
    tl.to(
      ".identity__kv--front",
      {
        rotate: "3deg",
        duration: 3.2,
        ease: "power3",
        opacity: 1,
        motionPath: {
          path: ".identity__motion-path__front",
          align: ".identity__motion-path__front",
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
          start: spOnly ? 0.5 : 0,
          end: 1,
        },
        clearProps: "all",
      },
      "show"
    );

    tl.to(
      ".identity__kv--back",
      {
        rotate: "12deg",
        duration: 3,
        // delay: 0.3,
        ease: "power3",
        opacity: 1,
        motionPath: {
          path: ".identity__motion-path__back",
          align: ".identity__motion-path__back",
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
          start: spOnly ? 0.5 : 0,
          end: 1,
        },
        clearProps: "all",
      },
      "show"
    );

    tl.set(".identity__kv__image", {
      opacity: 1,
    });

    ScrollTrigger.create({
      trigger: ".identity",
      start: "top center",
      animation: tl,
    });

    // パーツのインアニメーション

    const tlIn = gsap.timeline();
    tlIn.add(window.tl.identityTitle(), "");
    tlIn.add(window.tl.identityLead(), "-=0.3");
    tlIn.to(
      titleSplit.chars,
      {
        y: 0,
        rotateX: "0deg",
        duration: 1.2,
        stagger: 0.05,
      },
      "-=2"
    );
    tlIn.add(window.tl.identityDescription(), "-=2");
    tlIn.add(window.tl.identityLink(), "-=1");

    ScrollTrigger.create({
      trigger: ".identity",
      start: "top bottom",
      animation: tlIn,
    });

    // パララックス
  }
  businessInDirection() {
    if (!spOnly) {
      gsap.set(".business__closet__wrap", {
        height: $(".business__closet__clothes").clientHeight,
      });

      $$(".business__closet__clothes").forEach((el, index, self) => {
        el.style.zIndex = self.length - index;
      });
      gsap.set(".business__closet__clothes", {
        scale: 1.3,
        clipPath: scaleClip(1.3),
      });

      const clothesHeight = $(".business__closet__clothes").clientHeight;
      gsap.to(".business__closet__wrap", {
        height: $(".business").clientHeight,
        ease: "none",
        scrollTrigger: {
          trigger: ".business",
          start: "top bottom",
          end: `bottom bottom`,
          scrub: true,
        },
      });

      gsap.to(".business__closet__clothes", {
        scale: 1,
        clipPath: scaleClip(1),
        ease: "none",
        scrollTrigger: {
          trigger: ".business",
          start: "top bottom",
          end: `bottom bottom`,
          scrub: true,
        },
        clearProps: "all",
      });

      const maxHeight = $(".business__title").clientHeight;
      const wh = window.innerHeight;
      const centerPos = (wh - maxHeight) / 2;
      ScrollTrigger.create({
        trigger: ".business",
        pin: ".business__title",
        start: `top top+=${centerPos}`,
        end: `bottom bottom-=${centerPos}`,
      });
      ScrollTrigger.create({
        trigger: ".business",
        pin: ".business__message",
        start: `top top+=${centerPos}`,
        end: `bottom bottom-=${centerPos}`,
      });

      const defaultText = $(".business__message__description").innerHTML;
      const lineSplit = () => {
        $(".business__message__description").innerHTML = defaultText;
        const splitText = new SplitText(".business__message__description", {
          type: "lines,chars",
        });
        const topPosArray = [];

        splitText.chars.forEach((char, index) => {
          const top = char.getBoundingClientRect().top;
          topPosArray.push(top);
        });
        // topPosArrayから重複を削除
        const uniqueTopPosArray = topPosArray.filter((x, i, self) => {
          return self.indexOf(x) === i;
        });
        splitText.chars.forEach((char, index) => {
          const top = char.getBoundingClientRect().top;
          char.classList.add("business__message--" + uniqueTopPosArray.indexOf(top));
        });

        let textArray = "";
        uniqueTopPosArray.forEach((el, index) => {
          let text = "";
          $$(`.business__message--${index}`).forEach((el) => {
            text += el.textContent;
          });
          textArray += `<span class="business__message__description--lines">${text}</span><br>`;
        });
        $(".business__message__description").innerHTML = textArray;
      };

      lineSplit();
      window.addEventListener("resize", () => {
        lineSplit();
      });

      const sectionEl = $(".business");
      gsap.set(".business__message__description--lines", {
        x: "-50px",
        y: "100%",
        clipPath: "inset(0% 0 100% 0)",
        display: "inline-block",
      });
      window.tl[sectionEl.classList[0] + "Description"] = () => {
        const tl = gsap.timeline();
        tl.to(".business__message__description--lines", {
          y: 0,
          x: 0,
          clipPath: "inset(0% 0 0% 0)",
          stagger: 0.08,
          ease: "haruka1.inOut",
          duration: 2,
          clearProps: "all",
        });
        return tl;
      };
    }
    // if (spOnly) {
    //   const itemHeight = 160;
    //   gsap.set(".business__closet__wrap", {
    //     height: `${itemHeight}rem`,
    //   });
    // }

    // パーツのインアニメーション

    const tlIn = gsap.timeline();
    tlIn.add(window.tl.businessTitleHorizontal(), "");
    tlIn.add(window.tl.businessLead(), "-=0.3");
    if (!spOnly) tlIn.add(window.tl.businessDescription(), "-=2");
    if (spOnly) {
      const itemHeight = 160;
      tlIn.to(
        ".business__closet__wrap",
        {
          height: `${itemHeight * 3}rem`,
          duration: 1.5,
          ease: "haruka1.inOut",
        },
        "-=2"
      );
    }
    tlIn.add(window.tl.businessLink(), "-=1");

    ScrollTrigger.create({
      trigger: ".business",
      start: "top bottom",
      animation: tlIn,
    });
  }
  screenDirection() {
    const fixHeightNum = $(".screen").getBoundingClientRect().height / 3;
    const deviceComplex = isTouchDevice || spOnly;
    gsap.set(".screen__back-image", {
      scale: 1.2,
    });
    gsap.to(".screen__wrap--career .screen__back-image", {
      scale: 1,
      ease: "none",
      scrollTrigger: {
        trigger: ".screen",
        start: "top bottom",
        end: `top+=${fixHeightNum + window.innerHeight * 0.8} top`,
        scrub: true,
      },
    });
    gsap.to(".screen__wrap--culture .screen__back-image", {
      scale: 1,
      ease: "none",
      scrollTrigger: {
        trigger: ".screen",
        start: `top+=${fixHeightNum} bottom`,
        end: `bottom top`,
        scrub: true,
      },
    });
    const wh = window.innerHeight;
    const endLine = deviceComplex ? `bottom top+=${wh}` : "bottom bottom";

    ScrollTrigger.create({
      trigger: ".screen",
      start: "top top",
      anticipatePin: 1,
      end: endLine,
      pin: ".screen__pin-box",
      pinSpacing: false,
    });
    if (!deviceComplex) {
      gsap.set(".screen__wrap--career .screen__back-image-box", {
        y: "-60vh",
      });
      gsap.to(".screen__wrap--career .screen__back-image-box", {
        y: "0vh",
        ease: "none",
        scrollTrigger: {
          trigger: ".screen",
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      });
    }

    const tl = gsap.timeline();
    // 小数点を切り捨て
    tl.to(
      ".screen__wrap--career",
      {
        startAt: {
          opacity: 1,
        },
        // clipPath: "inset(0 0 100% 0)",
        duration: 1.5,
        opacity: 0,
        ease: "none",
      },
      "hide"
    );

    ScrollTrigger.create({
      trigger: ".screen",
      animation: tl,
      // markers: true,
      start: `top+=${fixHeightNum} top`,
      end: `top+=${fixHeightNum + window.innerHeight * 0.8} top`,
      scrub: true,
      onUpdate: (e) => {
        // console.log(e.progress);
        $(".screen__wrap--career").style.pointerEvents = e.progress > 0.5 ? "none" : "auto";
      },
    });

    // フェードインのアニメーション
    const registerTl = [];
    $$(".screen__wrap").forEach((wrap) => {
      // console.log(deviceComplex);
      const label = wrap.classList[1].replace("screen__wrap--", "");
      if (!deviceComplex) {
        const titleEl = $(".screen__content__title", wrap);
        const titleSplit = new SplitText(titleEl, {
          type: "chars",
          charsClass: "screen__content__title--char",
        });
        gsap.set(titleSplit.chars, {
          y: "100%",
          rotateX: "90deg",
          display: "inline-block",
        });

        registerTl[label + "Title"] = () => {
          const tl = gsap.timeline();
          tl.to(titleSplit.chars, {
            y: "0%",
            rotateX: "0deg",
            duration: 1.5,
            ease: "haruka1.inOut",
            stagger: 0.02,
          });
          return tl;
        };
      }

      const leadEl = $(".screen__content__sub", wrap);
      gsap.set(".screen__content__sub", {
        clipPath: "inset(0 100% 0 0)",
      });
      registerTl[label + "Lead"] = () => {
        const tl = gsap.timeline();
        tl.to(leadEl, {
          clipPath: "inset(0 0% 0 0)",
          duration: 0.8,
          ease: "haruka1.inOut",
        });
        tl.to($(".screen__content__sub-cover", wrap), {
          clipPath: "inset(0 0% 0 100%)",
          duration: 0.8,
          ease: "haruka1.inOut",
        });
        return tl;
      };

      const linkEl = $(".parts-circle-link", wrap);
      gsap.set(linkEl, {
        opacity: 0,
        scale: 0.8,
        y: 20,
      });
      registerTl[label + "Link"] = () => {
        const tl = gsap.timeline();
        tl.to(linkEl, {
          opacity: 1,
          scale: 1,
          duration: 1.4,
          y: 0,
        });
        return tl;
      };
    });
    if (!deviceComplex) {
      gsap.to(".screen", {
        // startAt: {
        //   filter: "brightness(1)",
        // },
        y: `-${window.innerHeight / 5}`,
        // filter: "brightness(0.8)",
        ease: "none",
        scrollTrigger: {
          trigger: ".news",
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      });
    }

    const careerTL = gsap.timeline();
    if (!deviceComplex) careerTL.add(registerTl.careerTitle());
    careerTL.add(registerTl.careerLead(), deviceComplex ? "show" : "-=0.8");
    careerTL.add(registerTl.careerLink(), deviceComplex ? "show" : "-=0.8");
    ScrollTrigger.create({
      trigger: ".screen",
      animation: careerTL,
      start: deviceComplex ? "top top+=100" : "top center",
    });

    const cultureTL = gsap.timeline();
    if (!deviceComplex) cultureTL.add(registerTl.cultureTitle());
    cultureTL.add(registerTl.cultureLead(), deviceComplex ? "show" : "-=0.8");
    cultureTL.add(registerTl.cultureLink(), deviceComplex ? "show" : "-=0.8");
    ScrollTrigger.create({
      trigger: ".screen",
      animation: cultureTL,
      start: `top+=${fixHeightNum + window.innerHeight * 0.8} top`,
    });
  }
  newsDirection() {
    const tl = gsap.timeline();
    tl.add(window.tl.newsTitleHorizontal());
    tl.add(window.tl.newsLink(), "-=1");
    ScrollTrigger.create({
      trigger: ".news",
      animation: tl,
      start: "top bottom",
    });
  }
}
