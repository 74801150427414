import * as THREE from "three";
import common from "./Common";
import assets from "./Assets";
import mouse from "./Mouse";
import Panels from "./Panels";
import background from "./Background";
import animation from "./Animation";
import domControls from "./DomControls";
import Particles from "./Particles";
import { getter_configs, setter_configs } from "./status";

export default class Artwork {
  constructor(props) {
    this.props = props;
    this.isLoaded = false;
    this.init();
  }

  init() {
    common.init({
      $canvas: this.props.$canvas,
    });

    mouse.init();
    animation.init();

    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(20, common.aspect, 1, 1000000);
    this.camera.lookAt(this.scene.position);

    this.particles = new Particles();
    this.scene.add(this.particles.group);

    this.panels = new Panels();
    this.scene.add(this.panels.group);

    const $topWebgl = document.getElementById("top-webgl");

    domControls.init();

    this.loopFunc = this.loop.bind(this);

    EventBus.on("FINISH_LOADING", () => {
      this.isLoaded = true;
      background.init();

      EventBus.once("DONE_PRERENDER", () => {
        this.panels.init();
        this.particles.init();

        $topWebgl.style.visibility = "inherit";
      });
    });

    EventBus.on("START_INTERACTIVE", () => {
      mouse.enable();
      this.panels.mouseEnable();
    });

    EventBus.on("STOP_INTERACTIVE", () => {
      mouse.disable();
      this.panels.mouseDisable();
    });

    // EventBus.emit("STOP_WEBGL");
    // EventBus.emit("START_WEBGL");

    EventBus.on("START_WEBGL", () => {
      // console.log("start webgl");
      common.clock.start();
      mouse.adjust();
      animation.playOpening();
      this.resize();
    });

    EventBus.on("STOP_WEBGL", () => {
      // console.log("stop webgl");
      document.body.classList.remove("webgl-shown");
      animation.stop();
    });

    EventBus.on("DONE_START_WEBGL", () => {
      document.body.classList.add("webgl-shown");
    });

    EventBus.on("DONE_STOP_WEBGL", () => {
      common.clock.stop();
    });

    assets.load();
    this.loop();
    this.resize();
  }

  resize() {
    common.resize();
    var z = common.dimensions.y / Math.tan((this.camera.fov * Math.PI) / 360) / 2;
    this.camera.position.z = z;
    this.camera.lookAt(this.scene.position);
    this.camera.aspect = common.aspect;
    this.camera.updateProjectionMatrix();

    domControls.resize();

    if (this.isLoaded) {
      this.panels.resize();
      mouse.resize();
    }
  }

  update() {
    if (getter_configs("isRendering")) {
      common.update();
      mouse.update();

      if (this.isLoaded) {
        domControls.update();
        background.preupdate();
        background.update();
        this.scene.background = background.fbo.texture;
      }

      this.particles.update();
      this.panels.update(this.camera);

      common.renderer.setRenderTarget(null);
      common.renderer.render(this.scene, this.camera);
    }
  }

  loop() {
    this.update();

    window.requestAnimationFrame(this.loopFunc);
  }
}
