import * as THREE from "three";
import common from "./Common";
import assets from "./Assets";
import gsap from "gsap";
import mouse from "./Mouse";
import domControls from "./DomControls";

import { getter_configs, setter_configs } from "./status";

class Animation {
  constructor() {
    this.opening = {
      bg: 0,
      sliding: 0,
      panel: 0,
      isFirstTime: true,
    };
  }

  init() {
    this.$bottomText = document.querySelectorAll("#WebGLBottomText, .top-webgl .parts-circle-link");
    this.$baseTutorial = document.querySelector(".base-tutorial");
    this.$baseTutorialThumb = document.querySelector(".base-tutorial .thumb");
    this.$baseTutorialTail = document.querySelector(".base-tutorial__kv__tail");
    this.$baseTutorialArrows = document.querySelectorAll(".base-tutorial .arrow");
    // console.log(this.$baseTutorialArrows);
    // 左上から 1, 4, 2, 3
    // this.$baseTutorial = null;
    // this.$baseTutorialThumb = null;

    this.$overlay = document.getElementById("WebGLOverlay");
    // this.openingTL2 = this.createOpening(false);

    this.createHideOverlay();
    this.createShowOverlay();
  }

  createOpening(isFirstTime) {
    if (this.openingTL) this.openingTL.kill();
    const tl = (this.openingTL = gsap.timeline({
      // paused: true,
      onComplete: () => {
        EventBus.emit("DONE_START_WEBGL");
        if (isFirstTime) {
          this.$baseTutorial.style.display = "none";
        }
      },
    }));
    tl.set("body", {
      pointerEvents: "none",
    });

    tl.fromTo(
      this.opening,
      2,
      {
        bg: 0,
      },
      {
        bg: 1,
      }
    ).fromTo(
      this.opening,
      4,
      {
        sliding: 0,
      },
      {
        sliding: 1,
        ease: "power3.out",
      },
      "-=1"
    );

    if (common.isMobile && isFirstTime) {
      const thumbMove = 14;
      const tail = {
        width: 100,
        height: 56,
        gap: 10,
      };
      const minus = 0.4;
      gsap.set(this.$baseTutorialThumb, {
        opacity: 0,
      });
      tl.set(this.$baseTutorial, { visibility: "inherit" })
        .fromTo(this.$baseTutorial, 1, { opacity: 0 }, { opacity: 1 })

        // .to(
        //   this.$baseTutorialArrows[1],
        //   {
        //     duration: 0.3,
        //     fill: "#ff543e",
        //   },
        //   "first"
        // )
        // .to(
        //   this.$baseTutorialThumb,
        //   {
        //     startAt: {
        //       y: -thumbMove,
        //       x: -thumbMove,
        //     },
        //     duration: 1 - minus,
        //     opacity: 1,
        //     y: thumbMove,
        //     x: thumbMove,
        //   },
        //   "first"
        // )
        // .to(
        //   this.$baseTutorialThumb,
        //   {
        //     duration: 0.4,
        //     opacity: 0,
        //   },
        //   "first+=0.6"
        // )
        // .to(
        //   this.$baseTutorialTail,
        //   {
        //     duration: 1.2 - minus,
        //     x: (tail.width + tail.gap) * 1,
        //     y: (tail.height + tail.gap) * 1,
        //     ease: "power2.inOut",
        //   },
        //   "first"
        // )

        // .to(this.$baseTutorialArrows, {
        //   duration: 0.3,
        //   fill: "#C7C7C7",
        // })

        .to(
          this.$baseTutorialArrows[3],
          {
            duration: 0.3,
            fill: "#ff543e",
          },
          "second"
        )

        .to(
          this.$baseTutorialThumb,
          {
            startAt: {
              y: thumbMove,
              x: -thumbMove,
            },
            duration: 1 - minus,
            x: thumbMove,
            y: -thumbMove,
            opacity: 1,
          },
          "second"
        )
        .to(
          this.$baseTutorialThumb,
          {
            duration: 0.4,
            opacity: 0,
          },
          "second+=0.6"
        )
        .to(
          this.$baseTutorialTail,
          {
            startAt: {
              x: 0,
              y: 0,
            },
            duration: 1.2 - minus,
            x: (tail.width + tail.gap) * 1,
            y: (tail.height + tail.gap) * -1,
            ease: "power2.inOut",
          },
          "second"
        )
        .to(this.$baseTutorialArrows, {
          duration: 0.3,
          fill: "#C7C7C7",
        })
        .to(
          this.$baseTutorialArrows[2],
          {
            duration: 0.3,
            fill: "#ff543e",
          },
          "third"
        )

        .to(
          this.$baseTutorialThumb,
          {
            startAt: {
              y: -thumbMove,
              x: thumbMove,
            },
            duration: 1 - minus,
            x: -thumbMove,
            y: thumbMove,
            opacity: 1,
          },
          "third"
        )
        .to(
          this.$baseTutorialThumb,
          {
            duration: 0.4,
            opacity: 0,
          },
          "third+=0.6"
        )
        .to(
          this.$baseTutorialTail,
          {
            startAt: {
              x: 0,
              y: 0,
            },
            duration: 1.2 - minus,
            x: (tail.width + tail.gap) * -1,
            y: (tail.height + tail.gap) * 1,
            ease: "power2.inOut",
          },
          "third"
        )
        .set(this.$baseTutorialTail, {
          x: 0,
          y: 0,
        })
        .to(this.$baseTutorialThumb, {
          startAt: {
            scale: 1,
            x: 0,
            y: 0,
          },
          scale: 0.8,
          opacity: 1,
          ease: "power2",
          duration: 0.4,
          transformOrigin: "center center",
        })
        .to(
          this.$baseTutorialArrows,
          {
            opacity: 0,
            duration: 0.3,
            stagger: 0.1,
          },
          "fade-=0.5"
        )
        .to(
          ".base-tutorial__kv__item",
          {
            duration: 1,
            opacity: 0.2,
            stagger: {
              grid: "auto",
              from: "center",
              amount: 0.7,
            },
          },
          "fade"
        )
        .to(
          this.$baseTutorialTail,
          {
            duration: 0.8,
            scale: 1.8,
            ease: "power2.out",
          },
          "fade"
        )
        .to(
          this.$baseTutorialThumb,
          {
            scale: 1,
            opacity: 1,
            ease: "power2",
            duration: 0.3,
            transformOrigin: "center center",
          },
          "fade"
        )

        .to(this.$baseTutorial, 1, { opacity: 0 })
        .set(this.$baseTutorial, { visibility: "hidden" });
    }

    tl.add(() => {
      EventBus.emit("START_INTERACTIVE");
      EventBus.emit("SHOW_TEXT");
      document.body.classList.add("webgl-shown");
    }, "-=0.5")
      .fromTo(
        this.opening,
        2,
        {
          panel: 0,
        },
        {
          panel: 1,
          ease: "power3.out",
        },
        "-=2"
      )
      .fromTo(
        this.$bottomText,
        1,
        {
          opacity: 0,
        },
        {
          opacity: 1,
        },
        "-=2.5"
      );
    tl.set("body", {
      pointerEvents: "",
    });

    // return tl;
  }

  createHideOverlay() {
    this.hideOverlayTL = gsap.timeline({
      paused: true,
    });

    this.hideOverlayTL
      .to(this.$overlay, 1, {
        opacity: 0,
      })
      .set(this.$overlay, { visibility: "hidden" });
  }

  createShowOverlay() {
    this.showOverlayTL = gsap.timeline({
      paused: true,
      onStart: () => {
        gsap.set(this.$overlay, { visibility: "inherit" });
      },
      onComplete: () => {
        EventBus.emit("DONE_STOP_WEBGL");
        setter_configs("isRendering", false);
      },
    });

    this.showOverlayTL.to(this.$overlay, 1, {
      opacity: 1,
    });
  }

  playOpening() {
    setter_configs("isRendering", true);
    if (this.opening.isFirstTime) {
      // this.openingTL.play(0);
      this.createOpening(true);
      // console.log("base tutorial表示");
    } else {
      // this.openingTL2.play(0);
      this.createOpening(false);
      // console.log("base tutorial非表示");
    }

    this.opening.isFirstTime = false;
    this.hideOverlayTL.play(0);
  }

  stop() {
    this.showOverlayTL.play(0);
    EventBus.emit("STOP_INTERACTIVE");
    EventBus.emit("HIDE_TEXT");
  }
}

export default new Animation();
