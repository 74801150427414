import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { SplitText } from "gsap/SplitText";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { $, $$, cssVal, spOnly, isTouchDevice } from "../_global.js";
import ScrollBooster from "scrollbooster";

gsap.registerPlugin(ScrollTrigger, CustomEase, SplitText, ScrollSmoother, MorphSVGPlugin);

class Common {
  constructor() {
    window.tl = [];
  }
  init() {
    console.log("はるかリクルート");
    ScrollTrigger.config({
      ignoreMobileResize: true,
    });
    this.sectionTriggerStart = "top bottom";
    this.registerGsap();

    this.scrollSettings();

    // this.headerToggle();
    this.clickPageTop();
    this.baseEntryDirection();
    this.inBaseFaq();
    this.escapeMenu();

    // partsAnimation
    this.partsLinkDirection();
    this.popupDirection();
    this.arrowmoveLink();
    this.scrollTo();
    this.partsCircleLinkOver();
    this.setZabuton();

    // add window timeline
    this.faqDirection();
    this.inTitleAnime();
    this.inHorizontalTitleAnime();
    this.inLeadAnime();
    this.inSectionScroll();
    this.inSectionDescription();
    this.inSectionFadein();

    // base template direction

    this.baseInterviewDirection();
  }
  noPinchout() {
    /* ピッチインピッチアウトによる拡大縮小を禁止 */
    // console.log("noPinchout");
    document.body.addEventListener(
      "touchmove",
      (e) => {
        // console.log(e.touches.length);
        if (e.touches.length !== 1) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
    document.addEventListener(
      "touchstart",
      (e) => {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      },
      { passive: false }
    );
  }
  windowTlAdd() {
    this.partsLinkDirection();
    this.inTitleAnime();
    this.inHorizontalTitleAnime();
    this.inLeadAnime();
    this.inSectionScroll();
    this.inSectionDescription();
    this.inSectionFadein();
  }
  registerGsap() {
    CustomEase.create(
      "haruka1.inOut",
      "M0,0 C0.026,0 0.162,0.014 0.208,0.07 0.276,0.153 0.325,0.361 0.357,0.501 0.377,0.591 0.411,0.769 0.474,0.863 0.549,0.974 0.704,1 1,1 "
    );
  }
  scrollSettings() {
    if (spOnly || isTouchDevice) return;
    console.log("scrollSettings");
    console.log(window.scrollSmootherBody);
    if (window.scrollSmootherBody) {
      window.scrollSmootherBody.kill();
    }
    window.scrollSmootherBody = ScrollSmoother.create({
      ease: "expo.out",
      wrapper: ".l-content",
      content: ".container",
      smooth: 1.8,
      effects: true,
      smoothTouch: 0.1,
      id: "pageScrollSmooth",
    });
    ScrollTrigger.refresh();
    // console.log("scrollSettings", window.scrollSmootherBody);

    // window.addEventListener("wheel", (e) => {
    //   console.log(e.deltaY);
    //   if (e.deltaY === 0) window.scrollSmootherBody.refresh();
    // });
  }
  headerToggle() {
    const videoOpEl = $(".base-header__menu__video--op");
    const videoLoopEl = $(".base-header__menu__video--loop");

    if (!spOnly) {
      videoOpEl.addEventListener("loadeddata", () => {
        videoOpEl.pause();
      });
      videoLoopEl.addEventListener("loadeddata", () => {
        videoLoopEl.pause();
      });
      videoLoopEl.style.opacity = 0;
    }

    $(".base-header__menu-toggle").addEventListener("click", () => {
      $("html").toggleAttribute("menu-open");
      gsap.to(".base-header__menu", {
        scrollTo: 0,
        duration: 1,
      });
      // console.log($(".top-webgl").style.opacity);
      if (window.scrollSmootherBody)
        window.scrollSmootherBody.paused($("html").hasAttribute("menu-open"));
      if ($("html").hasAttribute("menu-open")) {
        const partsLinkEl = $(".parts-lean-link", $(".base-header__menu__experience"));
        console.log(partsLinkEl);
        // 動画の初期設定
        if (!spOnly) {
          videoOpEl.pause();
          videoLoopEl.pause();
          videoOpEl.currentTime = 0;
          videoLoopEl.currentTime = 0;
          videoLoopEl.style.opacity = 0;
          videoOpEl.style.opacity = 1;
        }
        gsap.set(".base-header__menu__experience", {
          visibility: $(".top-webgl").style.opacity == 0 ? "visible" : "hidden",
          opacity: 0,
        });

        // console.log($$("span, .parts-lean-link__arrow", partsLinkEl));
        gsap.set($$("span, .parts-lean-link__arrow", partsLinkEl), {
          clipPath: "inset(0% 0 100% 0)",
          y: "100%",
        });
        const tl = gsap.timeline({
          delay: 1,
        });
        tl.add(() => {
          if (!spOnly) {
            videoOpEl.play();
            videoOpEl.addEventListener("ended", () => {
              videoLoopEl.style.opacity = 1;
              videoLoopEl.play();
            });
          }
        });

        tl.to(
          ".base-header__menu__experience",
          {
            startAt: {
              scale: 0.95,
            },
            scale: 1,
            opacity: 1,
            duration: 0.6,
          },
          "in+=1"
        );
        tl.to(
          $$("span, .parts-lean-link__arrow", partsLinkEl),
          {
            clipPath: "inset(0% 0 0% 0)",
            duration: 0.4,
            y: "0%",
            stagger: 0.2,
          },
          "in+=1.2"
        );
      } else {
        // console.log("閉じる");

        const tl = gsap.timeline({});
        tl.set(".base-header", {
          pointerEvents: "none",
        });
        tl.to(
          ".base-header__menu__video",
          {
            opacity: 0,
            duration: 0.3,
            onComplete: () => {
              if (!spOnly) {
                videoOpEl.pause();
                videoLoopEl.pause();
                videoOpEl.currentTime = 0;
                videoLoopEl.currentTime = 0;
              }
            },
          },
          "menuClose"
        );

        tl.to(
          ".base-header__menu__experience",
          {
            opacity: 0,
            duration: 1,
          },
          "menuClose"
        );
        tl.set(".base-header", {
          pointerEvents: "",
        });
      }
      if (spOnly) {
        videoLoopEl.play();
        videoLoopEl.style.opacity = 1;
      }
    });
    $(".base-header__menu-toggle").addEventListener("mouseover", () => {
      if (!spOnly) {
        gsap.to(".base-header__menu-btn span", {
          color: cssVal("--color-orange-100"),
          duration: 0.3,
        });
        gsap.to(".base-header__menu-btn-bar", {
          background: cssVal("--color-orange-100"),
          duration: 0.3,
        });
      }
    });
    $(".base-header__menu-toggle").addEventListener("mouseleave", () => {
      gsap.to(".base-header__menu-btn span", {
        color: cssVal("--color-black-100"),
        duration: 0.3,
      });
      gsap.to(".base-header__menu-btn-bar", {
        background: cssVal("--color-black-100"),
        duration: 0.3,
      });
    });

    setTimeout(() => {
      gsap.set(".base-popup", {
        display: "none",
        pointerEvents: "none",
      });
    }, 1000);

    const open = () => {
      // console.log("開く");
      $(".base-header").toggleAttribute("popup-open");
      const tl = gsap.timeline();
      tl.set(".base-popup", {
        display: "block",
        pointerEvents: "auto",
      });
      tl.to(".base-popup", {
        opacity: 1,
        duration: 0.4,
      });

      gsap.to(".base-popup__marquee-wrap", { opacity: 0, duration: 0.3 });
      gsap.to("[popup-marquee='default']", { opacity: 1, duration: 0.3 });
      if (window.scrollSmootherBody) window.scrollSmootherBody.paused(true);
    };

    const close = () => {
      if (window.scrollSmootherBody) window.scrollSmootherBody.paused(false);
      $(".base-header").removeAttribute("popup-open");
      const tl = gsap.timeline();
      tl.to(".base-popup", {
        opacity: 0,
        duration: 0.4,
      });
      tl.set(".base-popup", {
        display: "none",
        pointerEvents: "none",
      });
    };

    // 開く時のアクション
    $(".base-header__entry").addEventListener("click", () => {
      if ($(".base-header").hasAttribute("popup-open")) {
        close();
      } else {
        open();
      }
    });

    // // 閉じる時のアクション
    $$(".base-popup__menu-btn, .base-popup__menu-btn--close").forEach((el) => {
      el.addEventListener("click", () => {
        if (!$(".base-header").hasAttribute("popup-open")) return;
        close();
      });
    });
    // .base-header__menu上でホイールしたら
    if (!spOnly) {
      $(".base-header__menu").addEventListener("wheel", (e) => {
        gsap.set(".base-header__menu__list", {
          pointerEvents: "none",
        });
        clearTimeout(this.headerMenuWheelTimer);
        this.headerMenuWheelTimer = setTimeout(() => {
          gsap.set(".base-header__menu__list", {
            pointerEvents: "",
          });
        }, 100);
      });
    }
  }
  headerMenuClose() {
    if ($("html").hasAttribute("menu-open")) {
      $(".base-header__menu-toggle").click();
    }
    if ($(".base-header").hasAttribute("popup-open")) {
      $(".base-popup__menu-btn--close").click();
    }
  }
  clickPageTop() {
    gsap.set(".base-footer__to-top__circle-black path", {
      drawSVG: "0%",
      rotate: "90deg",
      transformOrigin: "50% 50%",
    });
    $(".base-footer__to-top").addEventListener("click", () => {
      gsap.to(window, {
        scrollTo: 0,
        duration: 1,
        ease: "expo.out",
      });
    });
    $(".base-footer__to-top").addEventListener("mouseenter", () => {
      gsap.to(".base-footer__to-top__circle-black path", {
        drawSVG: "100%",
        rotate: "270deg",
        duration: 1.2,
        ease: "haruka1.inOut",
        transformOrigin: "50% 50%",
      });
    });
    $(".base-footer__to-top").addEventListener("mouseleave", () => {
      gsap.to(".base-footer__to-top__circle-black path", {
        drawSVG: "0%",
        rotate: "90deg",
        duration: 1.2,
        ease: "haruka1.inOut",
        transformOrigin: "50% 50%",
      });
    });
  }
  baseEntryDirection() {
    if ($(".base-entry") === null) return;

    function handleTouchMove(event) {
      event.preventDefault();
    }
    const section = ".container, .news";
    const orange = cssVal("--color-orange-100");
    const white = cssVal("--color-white-200");
    const black = cssVal("--color-black-100");

    ScrollTrigger.create({
      trigger: ".base-entry",
      start: "top-=300 top",
      onEnter: () => {
        $("html").setAttribute("orange-back", "");
        gsap.to(section, {
          backgroundColor: orange,
          duration: 0.5,
          color: white,
        });
        gsap.to("[change-inherit-color]", {
          duration: 0.5,
          stroke: white,
          borderColor: white,
        });
      },
      onLeaveBack: () => {
        if ($(".base-entry").getAttribute("entry-back") === "false") return;
        $("html").removeAttribute("orange-back");
        gsap.to(section, {
          backgroundColor: white,
          duration: 0.5,
          color: black,
        });
        gsap.to("[change-inherit-color]", {
          duration: 0.5,
          stroke: black,
          borderColor: black,
        });
      },
    });

    // SPの横にスクロール
    if (spOnly) {
      const limit = $(".base-entry__list").offsetWidth - $(".base-entry__list-slider").offsetWidth;
      const progressLimit =
        $(".base-entry__slider-progress").offsetWidth -
        $(".base-entry__slider-progress__bar").offsetWidth;

      // $(".base-entry__list-slider")にタッチしたら
      $(".base-entry__list-slider").addEventListener("touchstart", (e) => {
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
      });
      $(".base-entry__list-slider").addEventListener("touchend", (e) => {
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
      });
      new ScrollBooster({
        viewport: $(".base-entry__list-slider"),
        content: $(".base-entry__list"),
        scrollMode: "native",
        direction: "horizontal",
        nativeScroll: true,
        onUpdate: (e) => {
          const pos = Math.round(e.position.x);
          const progress = (pos / limit) * progressLimit;
          gsap.set(".base-entry__slider-progress__bar", {
            x: progress,
          });
        },
      });
    }
  }
  inBaseFaq() {
    ScrollTrigger.create({
      trigger: ".base-faq",
      start: `top top+=60`,
      onEnter: () => {
        $("html").setAttribute("in-faq", "");
      },
      onLeaveBack: () => {
        $("html").removeAttribute("in-faq");
      },
    });
  }
  escapeMenu() {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        if ($("html").hasAttribute("menu-open")) $(".base-header__menu-toggle").click();
        if ($(".base-header").hasAttribute("popup-open")) $(".base-popup__menu-btn--close").click();
      }
    });
  }
  partsLinkDirection() {
    // inした時の動き
    $$(".parts-lean-link").forEach((linkEl) => {
      if (linkEl.hasAttribute("no-in")) return;
      const sectionEl = linkEl.closest("section");
      if (sectionEl === null) return;
      if (sectionEl.classList[0] === "screen") return;
      const sectionClassName = sectionEl.getAttribute("class");
      const text = $("span", linkEl);
      const arrow = $("svg", linkEl);

      gsap.set(text, {
        y: "100%",
        clipPath: "inset(0% 0 100% 0)",
      });
      gsap.set(arrow, {
        x: "-100%",
        opacity: 0,
      });

      const delay = () => {
        if (linkEl.getAttribute("in-delay") !== "null") {
          return linkEl.getAttribute("in-delay");
        }
        if (sectionClassName === "faq" || "career") {
          return 1;
        } else {
          return 2.2;
        }
      };

      const sectionName = sectionEl.classList[0] + "Link";
      // もしもsectionNameがケバブケースだったらキャメルケースに変換する
      const sectionNameCamel = sectionName.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
      window.tl[sectionNameCamel] = () => {
        const tl = gsap.timeline();
        tl.to(
          text,
          {
            y: "0%",
            clipPath: "inset(0% 0 0% 0)",
            duration: 1.2,
            ease: "haruka1.inOut",
          },
          "link"
        );
        tl.to(
          arrow,
          {
            x: "0%",
            opacity: 1,
            duration: 1.2,
            ease: "haruka1.inOut",
          },
          "link"
        );
        return tl;
      };
    });
    // ホバーした時の動き
    $$(".parts-lean-link").forEach((el) => {
      // elの親にbutton要素、もしくはa要素があったらreturn
      const splitText = new SplitText($("span", el), {
        type: "chars",
        charsClass: "parts-lean-link--char",
      });

      const tl = gsap.timeline({
        paused: true,
      });
      tl.to(
        splitText.chars,
        {
          x: "40%",
          duration: 0.5,
          ease: "power2.in",
          opacity: 0,
        },
        "enter"
      );
      tl.to(
        splitText.chars,
        {
          startAt: {
            x: "-40%",
          },
          x: "0%",
          duration: 0.5,
          clipPath: "inset(0 0 0 0%)",
          ease: "power2.out",
          stagger: 0.04,
          opacity: 1,
        },
        "in"
      );
      if (el.getAttribute("align") === "center") {
        gsap.set($(".parts-lean-link__arrow path", el), {
          morphSVG: "m29.934 1 6.836 6.836-6.836 6.835M23 7.894h13.904",
        });
        tl.to(
          $(".parts-lean-link__arrow path", el),
          {
            morphSVG: "m61.03 1 6.836 6.836-6.836 6.835M23 7.894h45",
            duration: 0.6,
            ease: "power3",
          },
          "enter"
        );
        tl.to(
          $(".parts-lean-link__arrow path", el),
          {
            x: "100%",
            duration: 0.3,
            ease: "power2",
          },
          "enter+=0.3"
        );
        tl.set($(".parts-lean-link__arrow path", el), {
          x: "-200%",
        });
        tl.to(
          $(".parts-lean-link__arrow path", el),
          {
            x: "0%",
            morphSVG: "m29.934 1 6.836 6.836-6.836 6.835M0 7.894h36.904",
            duration: 0.5,
            ease: "power2.out",
          }
          // "in+=0.5"
        );
        tl.to(
          $(".parts-lean-link__arrow path", el),
          {
            x: "0%",
            morphSVG: "m29.934 1 6.836 6.836-6.836 6.835M23 7.894h13.904",
            duration: 0.5,
            ease: "power2.out",
          },
          "-=0.3"
        );
      } else {
        tl.to(
          $(".parts-lean-link__arrow path", el),
          {
            morphSVG: "m60.93.64 6.84 6.84c-2.28 2.28-4.56 4.55-6.84 6.83M0 7.54h67.9",
            duration: 0.5,
            ease: "power2",
          },
          "enter"
        );
        tl.to(
          $(".parts-lean-link__arrow path", el),
          {
            x: "100%",
            duration: 0.5,
            ease: "power2",
          },
          "enter+=0.3"
        );

        tl.set(
          $(".parts-lean-link__arrow path", el),
          {
            morphSVG: "m6.93.64 6.84 6.84c-2.28 2.28-4.56 4.55-6.84 6.83M0 7.54h13.9",
            opacity: 1,
            x: "-100%",
          },
          "in+=0.5"
        );
        tl.to($(".parts-lean-link__arrow path", el), {
          x: "0%",
          duration: 0.3,
          ease: "power3",
        });
      }

      // 例外の処理
      if (el.parentNode.classList.contains("screen__link")) {
        el.parentNode.addEventListener("mouseenter", () => {
          tl.seek(0);
          tl.play();
        });

        el.parentNode.addEventListener("click", () => {
          window.location.href = el.href;
        });
        return;
      }

      if (el.closest("button") !== null) {
        const parent = el.closest("button");
        parent.addEventListener("mouseenter", () => {
          tl.seek(0);
          tl.play();
        });
        return;
      }
      if (el.closest(".parts-circle-link")) {
        const parent = el.closest(".parts-circle-link");
        parent.addEventListener("mouseenter", () => {
          tl.seek(0);
          tl.play();
        });
        return;
      }

      el.addEventListener("mouseenter", () => {
        tl.seek(0);
        tl.play();
      });
    });
  }
  popupDirection() {
    $$(".base-popup__contents").forEach((contentsEl) => {
      contentsEl.addEventListener("mouseenter", () => {
        const getContents = contentsEl.getAttribute("popup-contents");
        const marqueeEl = Array.from($$(".base-popup__marquee-wrap")).find(
          (el) => el.getAttribute("popup-marquee") === getContents
        );
        gsap.to(".base-popup__marquee-wrap", { opacity: 0, duration: 1 });
        gsap.to(marqueeEl, { opacity: 1, duration: 1 });
      });
    });
  }
  arrowmoveLink() {
    $$(".small-arrow").forEach((el) => {
      const linkEl = el.closest("a") || el.closest("button");
      linkEl.setAttribute("arrow-link", "");
      if (linkEl.classList[0] === "base-footer__to-top") return;
      const tl = gsap.timeline({ paused: true });
      tl.to(el, {
        startAt: {
          x: "0%",
          clipPath: "inset(0 0% 0 0%)",
        },
        x: "200%",
        clipPath: "inset(0 100% 0 0%)",
        duration: 0.5,
        ease: "power2",
      });
      tl.to(el, {
        startAt: {
          x: "-200%",
          clipPath: "inset(0 0% 0 100%)",
        },
        x: "0%",
        clipPath: "inset(0 0% 0 0%)",
        duration: 0.5,
        ease: "power2",
      });

      linkEl.addEventListener("mouseenter", () => {
        tl.play(0);
      });
    });
    $$(".link-arrow").forEach((el) => {
      const linkEl = el.closest("a") || el.closest("button");
      linkEl.setAttribute("arrow-link", "");
      const tl = gsap.timeline({ paused: true });
      tl.to(el, {
        startAt: {
          x: "0%",
          y: "0%",
          clipPath: "inset(0% 0% 0% 0%)",
        },
        x: "200%",
        y: "-200%",
        clipPath: "inset(100% 100% 0% 0%)",
        duration: 0.5,
        ease: "power2",
      });
      tl.to(el, {
        startAt: {
          x: "-200%",
          y: "200%",
          clipPath: "inset(0% 0% 100% 100%)",
        },
        x: "0%",
        y: "0%",
        clipPath: "inset(0% 0% 0% 0%)",
        duration: 0.5,
        ease: "power2",
      });

      linkEl.addEventListener("mouseenter", () => {
        tl.play(0);
      });
    });
  }
  scrollTo() {
    $$("[scrollto]").forEach((el) => {
      // headerの高さを取得
      const target = el.getAttribute("scrollto");
      el.addEventListener("click", () => {
        if ($("html").hasAttribute("menu-open")) $(".base-header__menu-toggle").click();
        if ($(".top-webgl").style.opacity !== "0") $(".top-webgl .parts-circle-link").click();
        setTimeout(() => {
          if (spOnly) {
            gsap.to(window, {
              duration: 1,
              ease: "power2",
              scrollTo: {
                y: target,
                offsetY: 130,
              },
            });
          } else {
            const smoother = window.scrollSmootherBody;
            console.log(smoother);
            smoother.scrollTo(target, true, "top 130px");
          }
        }, 500);
      });
    });
  }
  inTitleAnime() {
    $$("[section-title]").forEach((titleEl) => {
      if (titleEl.getAttribute("section-title") === "done") return;
      titleEl.setAttribute("section-title", "done");

      const sectionEl = titleEl.closest("section");
      const titleText = titleEl.textContent;
      titleEl.style.overflowY = "hidden";
      const titleSplit = new SplitText(titleEl, {
        type: "lines, chars",
        linesClass: "section-title__child",
        charsClass: "section-title__child-chars",
        tag: "span",
      });
      const textEl = titleSplit.lines[0];
      gsap.set(titleSplit.chars, {
        y: "100%",
        rotateX: "90deg",
        display: "inline-block",
      });
      const title = sectionEl.classList[0];
      const titleCamel = title.replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });
      window.tl[titleCamel + "Title"] = () => {
        const tl = gsap.timeline();
        tl.to(titleSplit.chars, {
          y: "0%",
          rotateX: "0deg",
          duration: 1.5,
          ease: "haruka1.inOut",
          stagger: 0.02,
        });
        // tl.set(titleEl, {
        //   overflow: "auto",
        // });
        tl.set($(".section-title__child", titleEl), {
          overflow: "auto",
        });
        tl.call(() => {
          $$(".section-title__child-chars", titleEl).forEach((el) => {
            el.setAttribute("style", "");
          });
        });
        return tl;
      };
    });
  }
  inHorizontalTitleAnime() {
    $$("[section-title-horizontal]").forEach((titleEl) => {
      if (titleEl.getAttribute("section-title-horizontal") === "done") return;
      titleEl.setAttribute("section-title-horizontal", "done");
      const title = new SplitText(titleEl, {
        type: "chars",
        tag: "span",
      });
      const sectionEl = titleEl.closest("section");
      gsap.set(title.chars, {
        y: "-100%",
        display: "inline-block",
        clipPath: "inset(100% 0 0% 0)",
      });
      const titleCamel = sectionEl.classList[0].replace(/-([a-z])/g, function (g) {
        return g[1].toUpperCase();
      });

      window.tl[titleCamel + "TitleHorizontal"] = () => {
        const tl = gsap.timeline();
        tl.to(title.chars, {
          y: "0%",
          clipPath: "inset(0% 0 0% 0)",
          duration: 1.2,
          ease: "haruka1.inOut",
          stagger: 0.07,
        });
        return tl;
      };
    });
  }
  partsCircleLinkOver() {
    $$(".parts-circle-link").forEach((link) => {
      const whitePathEl = $(".parts-circle-link__circle--white", link);
      const grayPathEl = $(".parts-circle-link__circle--gray", link);

      gsap.set(whitePathEl, {
        drawSVG: "0%",
        transformOrigin: "50% 50%",
      });
      link.addEventListener("mouseenter", () => {
        gsap.to(whitePathEl, {
          drawSVG: "-100%",
          rotate: "180deg",
          duration: 1.6,
          ease: "haruka1.inOut",
          transformOrigin: "50% 50%",
        });
      });
      link.addEventListener("mouseleave", () => {
        gsap.to(whitePathEl, {
          drawSVG: "0%",
          rotate: "0deg",
          duration: 1.6,
          ease: "haruka1.inOut",
          transformOrigin: "50% 50%",
        });
      });
    });
  }

  setZabuton() {
    $$("[zabuton-line]").forEach((el) => {
      if (el.getAttribute("zabuton-line") === "done") return;
      el.setAttribute("zabuton-line", "done");

      const split = new SplitText(el, {
        type: "lines",
        linesClass: "zabuton-line",
        tag: "span",
      });
      split.lines.forEach((text) => {
        text.innerHTML = `<span class="zabuton-line__text">${text.textContent}</span>`;
        const div = document.createElement("div");
        div.classList.add("zabuton-line__line");
        text.appendChild(div);

        const isCenter = window.getComputedStyle(el).textAlign === "center";
        gsap.set(text, {
          margin: isCenter ? "0 auto" : "",
        });
      });
    });
  }

  faqDirection() {
    const tl = gsap.timeline();
    tl.add(window.tl["baseFaqLink"]());
    ScrollTrigger.create({
      trigger: ".base-faq",
      start: "top bottom",
      animation: tl,
    });
  }
  inLeadAnime() {
    $$("[section-lead]").forEach((leadEl) => {
      if (leadEl.getAttribute("section-lead") === "done") return;
      leadEl.setAttribute("section-lead", "done");
      const textTitle = leadEl.innerHTML;
      const sectionEl = leadEl.closest("section");
      const leadSplit = new SplitText(leadEl, {
        type: "lines",
        linesClass: "section-lead__child",
        tag: "span",
      });
      const leadElStyle = window.getComputedStyle(leadEl);
      const leadElPosition = leadElStyle.getPropertyValue("position");
      const verticalRlBool = leadElStyle.getPropertyValue("writing-mode") === "vertical-rl";
      if (leadElPosition !== "absolute") leadEl.style.position = "relative";

      const leadElChild = document.createElement("div");
      leadElChild.classList.add("section-lead__cover");
      leadEl.appendChild(leadElChild);

      if (verticalRlBool) {
        gsap.set($$(".section-lead__child, .section-lead__cover", leadEl), {
          clipPath: "inset(0% 0% 100% 0%)",
        });
      }

      window.tl[sectionEl.classList[0] + "Lead"] = () => {
        const tl = gsap.timeline({});
        tl.to(
          $(".section-lead__cover", leadEl),
          {
            scaleX: 1,
            duration: 0.8,
            ease: "expo.out",
          },
          "show"
        );
        tl.to(
          $(".section-lead__cover", leadEl),
          {
            onStart: () => {
              if ($(".section-lead__child", leadEl))
                $(".section-lead__child", leadEl).style.opacity = 1;
            },
            startAt: {
              transformOrigin: "right",
            },
            scaleX: 0,
            duration: 0.8,
            ease: "expo.out",
          },
          "-=0.3"
        );
        tl.call(() => {
          leadEl.innerHTML = textTitle;
        });
        return tl;
      };
    });
  }
  inSectionScroll() {
    $$("[section-scroll]").forEach((scrollEl) => {
      if (scrollEl.getAttribute("section-scroll") === "done") return;
      scrollEl.setAttribute("section-scroll", "done");

      const sectionEl = scrollEl.closest("section");
      // inのセット
      gsap.set("[section-scroll]", {
        y: "100%",
        clipPath: "inset(0% 0 100% 0)",
      });
      // inのアニメ
      window.tl[sectionEl.classList[0] + "ScrollOn"] = () => {
        const tl = gsap.timeline();
        tl.to(scrollEl, {
          y: "0%",
          clipPath: "inset(0% 0 0% 0)",
          duration: 1.2,
          ease: "haruka1.inOut",
        });
        return tl;
      };
      // loopのアニメ
      window.tl[sectionEl.classList[0] + "ScrollLoop"] = () => {
        const tl = gsap.timeline({
          repeat: -1,
          repeatDelay: 2,
        });
        tl.to(".top__scroll", {
          y: "-100%",
          // clipPath: "inset(100% 0 0% 0)",
          rotateX: "90deg",
          // perspective: 300,
          duration: 0.8,
          ease: "power3.out",
        });
        tl.set(".top__scroll", {
          y: "100%",
          // clipPath: "inset(0% 0 100% 0)",
          rotateX: "-90deg",
          // perspective: 300,
        });
        tl.to(".top__scroll", {
          y: "0%",
          // clipPath: "inset(0% 0 0% 0)",
          rotateX: "0deg",
          duration: 0.6,
          // perspective: 0,
        });
        return tl;
      };
    });
  }
  inSectionDescription() {
    $$("[section-description]").forEach((descriptionEl) => {
      if (descriptionEl.getAttribute("section-description") === "done") return;
      descriptionEl.setAttribute("section-description", "done");
      if (spOnly) {
        if (descriptionEl.hasAttribute("pc-only")) return;
      } else {
        if (descriptionEl.hasAttribute("sp-only")) return;
      }
      const direction = descriptionEl.getAttribute("section-description");
      const sectionEl = descriptionEl.closest("section");
      const messageText = new SplitText(descriptionEl, { type: "lines", tag: "span" });
      if (!spOnly) {
        gsap.set(messageText.lines, {
          x: direction === "left" ? "-50px" : "50px",
          y: "100%",
          clipPath: "inset(0% 0 100% 0)",
        });
      } else {
        gsap.set(messageText.lines, {
          opacity: 0,
        });
      }
      window.tl[sectionEl.classList[0] + "Description"] = () => {
        const tl = gsap.timeline();
        if (!spOnly) {
          tl.to(messageText.lines, {
            y: 0,
            x: 0,
            clipPath: "inset(0% 0 0% 0)",
            stagger: 0.08,
            ease: "haruka1.inOut",
            duration: 2,
            clearProps: "clipPath,transform,posiiton",
          });
        } else {
          tl.to(messageText.lines, {
            opacity: 1,
            stagger: 0.08,
            ease: "haruka1.inOut",
            duration: 1,
            clearProps: "clipPath,transform,posiiton",
          });
        }

        return tl;
      };
    });
  }
  inSectionFadein() {
    $$("[section-fadein]").forEach((fadeinEl) => {
      if (fadeinEl.getAttribute("section-fadein") === "done") return;
      fadeinEl.setAttribute("section-fadein", "done");
      const sectionEl = fadeinEl.closest("section");
      gsap.set(fadeinEl, {
        y: 10,
        opacity: 0,
      });
      window.tl[sectionEl.classList[0] + "Fadein"] = () => {
        const tl = gsap.timeline();
        tl.to(fadeinEl, {
          y: 0,
          opacity: 1,
          ease: "power2",
          duration: 1.5,
        });
        return tl;
      };
    });
  }
  baseInterviewDirection() {
    if ($(".base-interview") === null) return;
    const moveNum = 200;

    const moveAnime = () => {
      // console.count("scroll");
      const cards = $$(".base-interview__item");
      const ww = window.innerWidth;
      cards.forEach((card, index) => {
        const pos = card.getBoundingClientRect().left + card.getBoundingClientRect().width / 2;
        const posPercent = pos / ww;
        const itemCard = $(".base-interview__item__image", card);

        gsap.set(card, {
          "--move-num": `${-moveNum * posPercent}px`,
        });
      });
    };

    if (!spOnly) {
      moveAnime();
      new ScrollBooster({
        viewport: $(".base-interview__slider-wrap"),
        content: $(".base-interview__slider"),
        scrollMode: "native",
        direction: "horizontal",
        nativeScroll: true,
        onUpdate: (e) => {
          moveAnime();
        },
      });
      $(".base-interview__slider-wrap").addEventListener("scroll", () => {
        moveAnime();
      });
    }

    const tl = gsap.timeline();
    // console.log(window.tl);
    tl.add(window.tl.baseInterviewTitle());
    if (!spOnly) {
      gsap.set(".base-interview__item", {
        x: -500,
        opacity: 0,
      });
      tl.to(
        ".base-interview__item",
        {
          x: 0,
          opacity: 1,
          duration: 2.3,
          ease: "expo.out",
          stagger: {
            amount: 0.07,
            from: "end",
            ease: "expo",
          },
          onUpdate: () => {
            if (!spOnly) moveAnime();
          },
        },
        "-=1.5"
      );
    }

    tl.add(window.tl.baseInterviewLink(), "-=1.4");
    ScrollTrigger.create({
      trigger: ".base-interview",
      animation: tl,
      start: "top bottom",
    });

    $$(".base-interview__item").forEach((el) => {
      const tl = gsap.timeline({ defaults: { ease: "none" }, paused: true });
      tl.to(
        $(".base-interview__item__image", el),
        {
          scale: 1.1,
          duration: 1.5,
        },
        "hover"
      );
      tl.to(
        $(".base-interview__item__title", el),
        {
          "--zabuton": "100%",
          duration: 1.5,
        },
        "hover"
      );

      el.addEventListener("mouseenter", () => {
        gsap.to(tl, {
          time: tl.duration(),
          duration: tl.duration(),
          ease: "power2",
        });
      });
      el.addEventListener("mouseleave", () => {
        gsap.to(tl, {
          time: 0,
          duration: tl.duration(),
          ease: "power2",
          overwrite: true,
        });
      });
    });

    // マウスストーカーのアニメーション
    const dragEl = $(".base-interview .parts-circle-cursor");
    $(".base-interview").addEventListener("pointermove", (e) => {
      gsap.to(dragEl, {
        duration: 1,
        x: e.clientX - dragEl.clientWidth / 2,
        y: e.clientY - $(".base-interview").getBoundingClientRect().y - dragEl.clientHeight / 2,
      });
    });
    gsap.set(dragEl, {
      opacity: 0,
    });
    $(".base-interview__slider").addEventListener("mouseenter", (e) => {
      gsap.to(dragEl, {
        opacity: 1,
        duration: 0.5,
      });
    });
    $(".base-interview__slider").addEventListener("mouseleave", (e) => {
      gsap.to(dragEl, {
        opacity: 0,
        duration: 0.5,
      });
    });
  }
}

export { Common };
