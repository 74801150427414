import * as THREE from "three";
import common from "./Common";
import assets from "./Assets";
import {selector, box} from "./domData"

import vertexShader from "./glsl/panel.vert";
import fragmentShader from "./glsl/panel.frag";
import shadowFragmentShader from "./glsl/panel_shadow.frag"

import mouse from "./Mouse";
import * as MATH from "./utils/math"
import background from "./Background";

import animation from "./Animation";
import domControls from "./DomControls";

import { getter_configs, setter_configs } from "./status";

const COLUMN_NUM = 15;
const MARGIN = 0.089;

class Plane{
  constructor(i, j, textureNum, commonUniforms){
    this.i = i;
    this.j = j;
    this.textureNum = textureNum
    this.commonUniforms = commonUniforms;
    this.group = new THREE.Group();
    this.init();
  }

  init(){
    const geometry = new THREE.PlaneBufferGeometry(1, 1, 1, 1);
    const focusVec = new THREE.Vector4(0.0, 0.0, 0.0, 0.0);

    this.uniforms = {
      ...this.commonUniforms,
      uTexture: {
        value: assets.textures["image_" +  this.textureNum].value
      },
      uFocus: {
        value: focusVec
      },
      uBlurTex: {
        value: background.textures[this.textureNum]
      },
      uShadowTex: {
        value: assets.textures.shadow.value
      },
      uHover: {
        value: 0
      }
    }

    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: this.uniforms,
      transparent: true,
      depthTest: false
    });
    this.mesh = new THREE.Mesh(
      geometry,
      material
    );

    this.group.userData = {
      order: new THREE.Vector2(this.i, this.j),
      defPos: new THREE.Vector2(),
      focus: {
        t: 0,
        c: 0,
        c2: 0
      },
      focus2: { //  マウスやスクロールしていないときの
        t: 0,
        c: 0,
        c2: 0
      },
      focusVec: focusVec,
      sliding: this.i % 2 == 0 ? 1 : -1,
      hover: {
        t: 0,
        c: 0
      }
    }

    this.mesh.renderOrder = 2;
    this.group.add(this.mesh);

    this.shadowMesh = new THREE.Mesh(
      geometry,
      new THREE.ShaderMaterial({
        vertexShader,
        fragmentShader: shadowFragmentShader,
        uniforms: this.uniforms,
        transparent: true,
        depthTest: false
      })
    );

    this.shadowMesh.renderOrder = 1;
    this.group.add(this.shadowMesh);
  }

  resize(width, height){
    const margin = width * MARGIN;

    this.group.scale.set(width, height, 1.0);
    this.shadowMesh.scale.set(1.06 * 1.2, 1.09 * 1.2, 1.0);

    const order = this.group.userData.order;

    this.group.userData.defPos.x = (margin + width) * (order.x - (COLUMN_NUM - 1) / 2);
    this.group.userData.defPos.y = (margin + height) * (order.y - (COLUMN_NUM - 1) / 2);
  }

  update(totalLength){
    const defPos = this.group.userData.defPos;

    this.group.position.x = defPos.x + mouse.totalDiff.x;
    this.group.position.y = defPos.y - mouse.totalDiff.y;
    this.group.position.y += MATH.lerp(totalLength.y * this.group.userData.sliding, 0, animation.opening.sliding);

    this.group.userData.focus.c += (this.group.userData.focus.t - this.group.userData.focus.c) * common.ease[4];
    this.group.userData.focus.c2 += (this.group.userData.focus.t - this.group.userData.focus.c2) * common.ease[0.5];

    this.group.userData.focus2.c += (this.group.userData.focus2.t - this.group.userData.focus2.c) * common.ease[0.5];
    this.group.userData.focus2.c2 += (this.group.userData.focus2.t - this.group.userData.focus2.c2) * common.ease[2];

    this.group.userData.focusVec.set(this.group.userData.focus.c, this.group.userData.focus.c2, this.group.userData.focus2.c, this.group.userData.focus2.c2);
  }

  updateHover(){
    this.group.userData.hover.c += (this.group.userData.hover.t - this.group.userData.hover.c) * common.ease[2];
    this.uniforms.uHover.value = this.group.userData.hover.c
  }
}

export {Plane, COLUMN_NUM, MARGIN}