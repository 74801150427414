import * as THREE from "three";
import { selector } from "./domData";

class Assets {
  constructor() {
    const path = `${location.origin}/wp-content/themes/haruka-recruit/static`;
    // const path = `${location.origin}/wp-content/themes/HARUKA_RECRUIT_WP`;
    this.textures = {
      spark: {
        src: path + "/images/webgl/assets/spark.jpg",
        value: null,
      },
      shadow: {
        src: path + "/images/webgl/assets/shadow.jpg",
        value: null,
      },
    };

    this.videos = {};

    this.total = 0;
    this.count = 0;
  }

  countTotal(obj) {
    for (let key in obj) {
      this.total++;
    }
  }
  compLoad() {
    this.count++;
    EventBus.emit("COUNT_LOADING", {
      progress: this.count / this.total,
    });
    if (this.count == this.total) {
      EventBus.emit("FINISH_LOADING");
    }
  }

  setTextures(name) {
    const $images = document.body.querySelectorAll(selector);
    for (let i = 0; i < $images.length; i++) {
      const $image = $images[i];
      this.textures[name + "_" + i] = {
        src: $image.getAttribute("data-src"),
        $image: $image,
        value: null,
      };
    }
  }

  load() {
    this.setTextures("image");
    this.countTotal(this.textures);

    this.loadTextures();
  }

  loadTextures() {
    for (let key in this.textures) {
      const data = this.textures[key];
      const loader = new THREE.TextureLoader();
      loader.load(data.src, (texture) => {
        data.value = texture;

        if (data.isLoop) {
          texture.wrapS = THREE.RepeatWrapping;
          texture.wrapT = THREE.RepeatWrapping;
        }
        this.compLoad();
      });
    }
  }

  update() {}
}

export default new Assets();
