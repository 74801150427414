import gsap from "gsap";
import { getter_configs, setter_configs } from "./status";

import EventBus from "../event-bus";
import mouse from "./Mouse";

class DomControls {
  constructor() {
    this.animations = [];
    this.hideNum = null;
    this.tweens = [];

    this.offsetW = "-3vw";
  }

  init() {
    const $texts = document.body.querySelectorAll("#top-webgl .texts");
    this.$texts = [];

    for (let i = 0; i < $texts.length; i++) {
      const $hash = $texts[i].querySelector(".hashtag");
      const $hash_bg = $hash.querySelector(".bg");
      const $hash_text_p = $hash.querySelector("p");
      const $hash_text_s = $hash_text_p.querySelector("span");

      const $message = $texts[i].querySelector(".message");
      const _$textlines = $message.querySelectorAll(".textline");
      const $textlines = [];

      for (let j = 0; j < _$textlines.length; j++) {
        const $textline = _$textlines[j];
        const $bg = $textline.querySelector(".bg");
        const $text_p = $textline.querySelector("p");
        const $text_s = $text_p.querySelector("span");

        $textlines[j] = {
          $textline,
          $bg,
          $text_p,
          $text_s,
        };
      }

      const $name = $texts[i].querySelector(".name");

      const $name_p = $name.querySelector("p");
      const $name_s = $name_p.querySelector("span");
      const $name_bg = $name.querySelector(".bg");

      // const $btn = $texts[i].querySelector(".parts-circle-cursor");

      this.$texts[i] = {
        $text: $texts[i],
        $hash,
        $hash_bg,
        $hash_text_p,
        $hash_text_s,
        message: {
          $textlines,
        },
        $name,
        $name_p,
        $name_s,
        $name_bg,
        // $btn
      };

      this.tweens[i] = {
        show: null,
        hide: null,
      };
    }

    EventBus.on("SHOW_TEXT", ({ detail }) => {
      this.show();
    });

    EventBus.on("HIDE_TEXT", ({ detail }) => {
      this.hide();
    });
  }

  show() {
    if (getter_configs("text.isShow")) return;

    EventBus.emit("SHRINK_PANEL");

    this.currentNum = getter_configs("current");
    setter_configs("text.isShow", true);
    // console.log("show", this.currentNum);

    const tweens = this.tweens[this.currentNum];
    if (tweens.hide) {
      tweens.hide.kill();
    }

    tweens.show = this.createShowTl(this.currentNum);
  }

  hide() {
    if (!getter_configs("text.isShow")) return;
    setter_configs("text.isShow", false);
    EventBus.emit("EXPAND_PANEL");

    // console.log("hide", this.currentNum);

    const tweens = this.tweens[this.currentNum];
    if (tweens.show) {
      tweens.show.kill();
    }

    tweens.hide = this.createHideTl(this.currentNum);
  }

  createShowTl(i) {
    const $eles = this.$texts[i];

    const tl = gsap.timeline();
    const ease = "power3.out";

    tl.set($eles.$text, { visibility: "inherit" })
      .to($eles.$hash, 1, {
        x: "0%",
        ease,
      })
      .to(
        $eles.$hash_bg,
        0.5,
        {
          x: "0%",
          ease,
        },
        0
      )
      .to(
        $eles.$hash_text_p,
        1,
        {
          x: "0%",
          ease,
        },
        0.2
      )
      .to(
        $eles.$hash_text_s,
        1,
        {
          x: "0%",
          ease,
        },
        0.2
      );

    for (let i = 0; i < $eles.message.$textlines.length; i++) {
      const { $textline, $text_p, $text_s, $bg } = $eles.message.$textlines[i];
      const delay = 0.2 + i * 0.1;
      tl.to(
        $textline,
        1,
        {
          x: "0%",
          ease,
        },
        delay
      )
        .to(
          $bg,
          0.5,
          {
            x: "0%",
            ease,
          },
          delay
        )
        .to(
          $text_p,
          1,
          {
            x: "0%",
            ease,
          },
          delay + 0.1
        )
        .to(
          $text_s,
          1,
          {
            x: "0%",
            ease,
          },
          delay + 0.1
        );
    }

    tl.to(
      $eles.$name,
      1,
      {
        x: "0%",
        ease,
      },
      0.4
    )
      .to(
        $eles.$name_p,
        1.5,
        {
          x: "0%",
          ease,
        },
        0.4
      )
      .to(
        $eles.$name_s,
        1.5,
        {
          x: "0%",
          ease,
        },
        0.4
      )
      .to(
        $eles.$name_bg,
        {
          x: "0%",
          ease,
        },
        0.4
      );

    // tl
    // .to($eles.$btn, 0.5, {
    //   opacity: 1,
    //   scale: 1
    // }, 0.5)
    return tl;
  }

  createHideTl(i) {
    const $eles = this.$texts[i];

    const tl = gsap.timeline({
      onComplete: () => {
        $eles.$text.style.visibility = "";
      },
    });
    const ease = "power3.out";

    tl.to($eles.$hash_text_p, 0.5, {
      x: "-100%",
      ease,
    })
      .to(
        $eles.$hash_text_s,
        0.5,
        {
          x: "100%",
          ease,
        },
        0
      )
      .to(
        $eles.$hash_bg,
        0.5,
        {
          x: "-100%",
          ease,
        },
        0.0
      );
    // .to($eles.$hash, 1, {
    //   x: this.offsetW,
    //   ease
    // }, 0)

    for (let i = 0; i < $eles.message.$textlines.length; i++) {
      const { $textline, $text_p, $text_s, $bg } = $eles.message.$textlines[i];
      const delay = 0.1;
      tl
        // .to($textline, 1, {
        //   x: this.offsetW,
        //   ease
        // }, delay)
        .to(
          $bg,
          0.5,
          {
            x: "-100%",
            ease,
          },
          delay
        )
        .to(
          $text_s,
          0.5,
          {
            x: "100%",
            ease,
          },
          delay
        )
        .to(
          $text_p,
          0.5,
          {
            x: "-100%",
            ease,
          },
          delay
        );
    }

    tl
      // .to($eles.$name, 1, {
      //   x: "-6vw",
      //   ease
      // }, 0.2)
      .to(
        $eles.$name_p,
        0.5,
        {
          x: "-100%",
          ease,
        },
        0.2
      )
      .to(
        $eles.$name_s,
        0.5,
        {
          x: "100%",
          ease,
        },
        0.2
      )
      .to(
        $eles.$name_bg,
        0.5,
        {
          x: "-100%",
          ease,
        },
        0.2
      );

    // tl
    // .to($eles.$btn, 0.3, {
    //   opacity: 0
    // }, 0.0)
    // .to($eles.$btn, 0.3, {
    //   scale: 0.5
    // }, 0.0)

    return tl;
  }

  appendWidth($p, $s) {
    const w = $s.clientWidth;
    $p.style.width = w + "px";
  }

  resize() {
    for (let i = 0; i < this.$texts.length; i++) {
      const { $hash_text_p, $hash_text_s, message, $name_p, $name_s } = this.$texts[i];
      this.appendWidth($hash_text_p, $hash_text_s);
      this.appendWidth($name_p, $name_s);

      for (let j = 0; j < message.$textlines.length; j++) {
        const { $text_p, $text_s } = message.$textlines[j];
        this.appendWidth($text_p, $text_s);
      }
    }
  }

  update() {
    for (let i = 0; i < this.$texts.length; i++) {
      const $eles = this.$texts[i];
      const $text = $eles.$text;
      $text.style.transform =
        "translate(" + mouse.hoverPos2.x * 0.02 + "px, " + -mouse.hoverPos2.y * 0.02 + "px)";
    }
  }
}

export default new DomControls();
