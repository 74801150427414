import EventBus from "../webgl/event-bus";
import gsap from "gsap";
import { GSDevTools } from "gsap/GSDevTools";
import { $, $$, cssVal, scaleClip, spOnly, randomId } from "../_global.js";

gsap.registerPlugin(GSDevTools);
const $loadingBlock = document.getElementById("loadingBlock");
const $loadingProgress = document.getElementById("loadingProgress");
const $introScene1 = document.getElementById("introScene1");
const $introScene2 = document.getElementById("introScene2");

export default function () {
  const countLoading = () => {
    const count = {
      num: 0,
    };
    const tl = gsap.timeline({
      // paused: true,
      delay: 0.2,
      onComplete: () => {
        gsap.set(".base-loading", { display: "none" });
        EventBus.emit("START_WEBGL");
      },
    });
    tl.to(
      ".base-loading",
      {
        background: cssVal("--color-white-200"),
        duration: 0.2,
      },
      "countShow"
    );
    tl.to(
      ".base-loading__logo, .base-loading__message__main, .base-loading__message__sub, .base-loading__count",
      {
        opacity: 1,
        duration: 1,
        scale: 1,
        y: 0,
        stagger: {
          amount: 0.5,
        },
      },
      "countShow+=0.4"
    );
    tl.to(
      ".base-loading__count__progress",
      {
        "--progress": "100%",
        duration: 2,
      },
      "count-=1"
    );

    tl.to(
      count,
      {
        duration: 2,
        num: 100,
        onUpdate: () => {
          const roundNum = Math.round(count.num);
          const numStr = String(roundNum).padStart(3, "0");
          $(".base-loading__count__num").innerText = numStr;
        },
      },
      "count-=1"
    );

    tl.to(
      ".base-loading__logo, .base-loading__message__main, .base-loading__message__sub, .base-loading__count",
      {
        opacity: 0,
        duration: 0.7,
        scale: 1,
        y: 0,
        stagger: {
          amount: 0.5,
        },
      }
    );
    tl.to(".base-loading", {
      opacity: 0,
      duration: 0.7,
    });
    // GSDevTools.create({
    //   animation: tl,
    // });
  };
  EventBus.on("DONE_PRERENDER", countLoading);
}
