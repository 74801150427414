const configs = {
  current: 0,
  isReady: false,
  isRendering: true,
  old: 0,
  text: {
    triggerShow: false,
    triggerHide: false,
    isShow: false
  }
}


export const getter_configs = (key) => {
  const keyArray = key.split(".");

  let result = configs;
  for(let i = 0; i < keyArray.length; i++){
      result = result[keyArray[i]]
  }

  return result;
}

export const setter_configs = (key, value) => {
  const keyArray = key.split(".");

  let result = configs;
  for(let i = 0; i < keyArray.length - 1; i++){
      result = result[keyArray[i]]
  }

  result[keyArray[keyArray.length - 1]] = value;
}