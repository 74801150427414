import Artwork from "./modules/Artwork";
import EventBus from "./event-bus";
import { $, $$, cssVal, spOnly } from "../_global.js";

export default function () {
  window.EventBus = EventBus;

  const $canvas = document.getElementById("webgl-canvas");

  const artwork = new Artwork({
    $canvas,
  });

  window.addEventListener("resize", () => {
    artwork.resize();
  });
}
