import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import ScrollBooster from "scrollbooster";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";

gsap.registerPlugin(ScrollTrigger, SplitText);

export class BusinessPage {
  constructor() {
    this.$el = "[business-page]";
  }
  init() {
    if ($(this.$el)) {
      this.openingAnimeSet();
      this.openingAnime();
      this.strengthsDirection();
      this.salonDirection();
      this.artistDirection();
      // console.log("window timeline", window.tl);
    }
  }
  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");

    gsap.set(".mind", {
      opacity: 0,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");
    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");

    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.to(
      ".mind",
      {
        opacity: 1,
        duration: 1.5,
      },
      "contents-=0.3"
    );
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
  strengthsDirection() {
    gsap.set(".strengths .zabuton-line__line", {
      transformOrigin: "left center",
      scaleX: 0,
    });
    $$(".strengths__contents").forEach((el, index) => {
      const tl = gsap.timeline();
      tl.to($$(".zabuton-line__line", el), {
        scaleX: 1,
        duration: 1.2,
        ease: "power2",
        stagger: 0.2,
      });
      ScrollTrigger.create({
        trigger: el,
        animation: tl,
        start: "top center",
      });
    });
  }
  salonDirection() {
    // scrollAnime
    const parentEl = $(".salon__contents");
    if (!spOnly) {
      gsap.set($(".business__closet__wrap", parentEl), {
        height: $(".business__closet__clothes", parentEl).clientHeight,
      });

      $$(".business__closet__clothes", parentEl).forEach((el, index, self) => {
        el.style.zIndex = self.length - index;
      });
      gsap.set($(".business__closet__clothes", parentEl), {
        scale: 1.3,
        clipPath: scaleClip(1.3),
      });

      const clothesHeight = $(".business__closet__clothes", parentEl).clientHeight;
      gsap.to($(".business__closet__wrap", parentEl), {
        height: parentEl.clientHeight,
        ease: "none",
        scrollTrigger: {
          trigger: parentEl,
          start: "top bottom",
          end: `bottom bottom`,
          scrub: true,
        },
      });

      gsap.to($(".business__closet__clothes", parentEl), {
        scale: 1,
        clipPath: scaleClip(1),
        ease: "none",
        scrollTrigger: {
          trigger: parentEl,
          start: "top bottom",
          end: `bottom bottom`,
          scrub: true,
        },
      });
      const maxHeight = $(".business__head", parentEl).clientHeight;
      const wh = window.innerHeight;
      const centerPos = (wh - maxHeight) / 2;
      ScrollTrigger.create({
        trigger: parentEl,
        pin: $(".business__head", parentEl),
        start: `top top+=${centerPos}`,
        end: `bottom bottom-=${centerPos}`,
      });
      ScrollTrigger.create({
        trigger: parentEl,
        pin: $(".business__info", parentEl),
        start: `top top+=${centerPos}`,
        end: `bottom bottom-=${centerPos}`,
      });
    }

    // パーツのインアニメーション
    const tlIn = gsap.timeline();
    tlIn.add(window.tl.salon__contentsTitleHorizontal(), "");
    tlIn.add(window.tl.salon__contentsLead(), "-=0.3");
    tlIn.add(window.tl.salon__contentsFadein(), "-=0.8");
    ScrollTrigger.create({
      trigger: ".salon__contents",
      start: "top bottom",
      animation: tlIn,
    });
  }
  artistDirection() {
    // scrollAnime
    const parentEl = $(".artist__contents");
    if (!spOnly) {
      gsap.set($(".business__closet__wrap", parentEl), {
        height: $(".business__closet__clothes", parentEl).clientHeight,
      });

      $$(".business__closet__clothes", parentEl).forEach((el, index, self) => {
        el.style.zIndex = self.length - index;
      });
      gsap.set($(".business__closet__clothes", parentEl), {
        scale: 1.3,
        clipPath: scaleClip(1.3),
      });

      const clothesHeight = $(".business__closet__clothes", parentEl).clientHeight;
      gsap.to($(".business__closet__wrap", parentEl), {
        height: parentEl.clientHeight,
        ease: "none",
        scrollTrigger: {
          trigger: parentEl,
          start: "top bottom",
          end: `bottom bottom`,
          scrub: true,
        },
      });

      gsap.to($(".business__closet__clothes", parentEl), {
        scale: 1,
        clipPath: scaleClip(1),
        ease: "none",
        scrollTrigger: {
          trigger: parentEl,
          start: "top bottom",
          end: `bottom bottom`,
          scrub: true,
        },
      });
      const maxHeight = $(".business__head", parentEl).clientHeight;
      const wh = window.innerHeight;
      const centerPos = (wh - maxHeight) / 2;
      ScrollTrigger.create({
        trigger: parentEl,
        pin: $(".business__head", parentEl),
        start: `top top+=${centerPos}`,
        end: `bottom bottom-=${centerPos}`,
      });
      ScrollTrigger.create({
        trigger: parentEl,
        pin: $(".business__info", parentEl),
        start: `top top+=${centerPos}`,
        end: `bottom bottom-=${centerPos}`,
      });
    }
    // パーツのインアニメーション
    const tlIn = gsap.timeline();
    tlIn.add(window.tl.artist__contentsTitleHorizontal(), "");
    tlIn.add(window.tl.artist__contentsLead(), "-=0.3");
    tlIn.add(window.tl.artist__contentsFadein(), "-=0.8");

    ScrollTrigger.create({
      trigger: ".artist__contents",
      start: "top bottom",
      animation: tlIn,
    });
  }
}
