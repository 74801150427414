import * as THREE from "three"

class Common {
    constructor() {
        this.$wrapper = null;
        this.dimensions = new THREE.Vector2();
        this.dimensions_old = new THREE.Vector2();
        this.aspect = null;
        this.isMobile = false;
        this.pixelRatio = null;

        this.fbo_dimensions = new THREE.Vector2();

        this.time = 0;
        this.delta = 0;

        this.ease = {
            4: this.calcEase(4),
            0.5: this.calcEase(0.5),
            2: this.calcEase(2)
        }
    }

    init({ $canvas }) {
        this.pixelRatio = Math.min(1.5, window.devicePixelRatio);

        this.$canvas = $canvas;

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: this.$canvas
        });

        this.renderer.setClearColor(0xffffff);

        this.renderer.setPixelRatio(this.pixelRatio);

        this.clock = new THREE.Clock();
        this.clock.start();
        this.resize();
    }

    resize() {
        const width = document.body.clientWidth;
        const height = window.innerHeight;

        this.dimensions_old.copy(this.dimensions);
        this.dimensions.set(width, height);

        this.fbo_dimensions.set(
            width * this.pixelRatio,
            height * this.pixelRatio
        );

        this.isMobile = width <= 750

        this.aspect = width / height;

        this.renderer.setSize(this.dimensions.x, this.dimensions.y);
    }

    calcEase(speed){
        return Math.min(1.0, this.delta * speed);
    }

    update() {
        const delta = this.clock.getDelta();
        this.delta = delta;
        this.time += this.delta;

        for(let key in this.ease){
            this.ease[key] = this.calcEase(key)
        }
        
    }
}

export default new Common();