import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import ScrollBooster from "scrollbooster";
import { $, $$, cssVal, scaleClip, spOnly } from "../_global.js";

gsap.registerPlugin(ScrollTrigger, SplitText);

export class InterviewPage {
  constructor() {
    this.$el = "[interview-page]";
  }
  init() {
    if ($(this.$el)) {
      // this.interviewCardMarquee();
      this.openingAnimeSet();
      this.openingAnime();
      // console.log("window timeline", window.tl);
    }
  }
  openingAnimeSet() {
    if ($(this.$el) === null) return;
    if (
      $(this.$el).getAttribute("opening") === "set" ||
      $(this.$el).getAttribute("opening") === "done"
    )
      return;
    $(this.$el).setAttribute("opening", "set");
    this.titleSubText = new SplitText(".top__title-sub", {
      type: "chars",
      tag: "span",
      charsClass: "top__title-sub--char",
    });
    gsap.set(this.titleSubText.chars, {
      x: -4,
      opacity: 0,
      display: "inline-block",
    });
    gsap.set(".interview__list", {
      opacity: 0,
      y: 30,
    });
  }
  openingAnime() {
    if ($(this.$el) === null) return;
    if ($(this.$el).getAttribute("opening") === "done") return;
    $(this.$el).setAttribute("opening", "done");

    const tl = gsap.timeline();
    tl.add(window.tl["topTitle"]());
    tl.add(window.tl["topLead"](), "lead-=0.2");
    tl.to(
      this.titleSubText.chars,
      {
        x: "0%",
        opacity: 1,
        duration: 1.2,
        ease: "haruka1.inOut",
        stagger: 0.02,
      },
      "lead-=0.2"
    );
    tl.to(
      ".interview__list",
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
      },
      "contents-=0.5"
    );
    tl.add(window.tl.topScrollOn, "contents-=0.5");
    tl.call(() => {
      window.tl.topScrollLoop().play();
    });
    window.openingTimeline = tl;
  }
}
